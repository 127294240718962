import { ICommandContext } from "@bigpi/cookbook";
import { FixedToolbar } from "@bigpi/cutlery";
import {
  ActionMenuFactory,
  Editor,
  CollaborativeBoardDocumentEditorConfig,
  EditorBase,
  EditorEvents,
  EditorOptions,
  getCommonEditorExtensions,
  IExtensionOptions,
  PageMargin,
  PageOrientation,
  PageSize,
  ToolbarFactory,
  useCollaborativeBoardDocumentEditor,
  getPageClassNames,
} from "@bigpi/editor-tiptap";
import { WebSocketProvider } from "@bigpi/y-websocket";
import { useAuthUser } from "@frontegg/react";
import { PostAddOutlined } from "@mui/icons-material";
import { generateHTML, getNodeType } from "@tiptap/core";
import type { Editor as TldrawEditor, TLShapeId } from "@tldraw/tldraw";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { CommandContext } from "CommandContext";
import Config from "Config";
import { ExportToWord } from "Editor/Extensions/ExportToWord/ExportToWordExtension";
import { useDoubtfireCleanup } from "Editor/Extensions/PasteContent/useDoubtfireCleanup";
import { ObjectRole, useLinkPropsLazyQuery, useWorkspaceBoardAccessControlListUserRolesQuery } from "GraphQL/Generated/Apollo";
import { getRandomColor } from "Utils/ColorUtils";
import { getExportHtmlToWordFn } from "Utils/ExportToWordUtil";
import { getPlaceholderFn } from "Utils/PlaceholderUtil";

export interface IHtmlDocumentShapeEditorProps {
  activeBookmarkIds: Array<string>;
  activeSelection?: [number, number];
  isEditing: boolean;
  onAddIFrameToBoard: (url: string) => void;
  onAuthError: (message: string) => void;
  onEditorChanged: (editor: Editor | null) => void;
  onSelectionUpdate?: (props: EditorEvents["selectionUpdate"]) => void;
  onUpdate: (props: EditorEvents["update"]) => void;
  pageMargin: PageMargin;
  pageName: string;
  pageOrientation: PageOrientation;
  pageSize: PageSize;
  provider: WebSocketProvider;
  shapeId: TLShapeId;
  tldrawEditor: TldrawEditor;
  workspaceBoardId: string;
  yDocumentFragment: any;
}

// *********************************************
// Private constants
// *********************************************/
const SELECTION_DEBOUNCE_TIME = 200;

// *********************************************
// Private methods
// *********************************************/
function _getCollaborativeConfig(user: ReturnType<typeof useAuthUser>, provider: WebSocketProvider, yDocumentFragment: any) {
  return {
    editorUser: {
      accessToken: user.accessToken,
      color: getRandomColor(),
      name: user.name,
    },
    provider,
    yDocumentFragment,
  };
}

// *********************************************
// Public methods
// *********************************************/
export function HtmlDocumentShapeEditor(props: IHtmlDocumentShapeEditorProps) {
  const {
    activeBookmarkIds,
    activeSelection = [1, 1],
    isEditing,
    onAddIFrameToBoard,
    onEditorChanged,
    onSelectionUpdate,
    onUpdate,
    pageMargin,
    pageName,
    pageOrientation,
    pageSize,
    provider,
    shapeId,
    tldrawEditor,
    workspaceBoardId,
    yDocumentFragment,
  } = props;

  const { t } = useTranslation();

  const user = useAuthUser();

  const [editorOptions, setEditorOptions] = React.useState<Partial<EditorOptions>>();
  const [extensionOptions, setExtensionOptions] = React.useState<IExtensionOptions>();
  const [collaborativeConfig, setCollaborativeConfig] = React.useState<CollaborativeBoardDocumentEditorConfig>(
    _getCollaborativeConfig(user, provider, yDocumentFragment),
  );
  const [fetchLinkProps] = useLinkPropsLazyQuery();

  const getUserToken = React.useCallback(() => {
    return user.accessToken;
  }, [user]);

  const {
    data: aclData,
    error: aclError,
    loading: aclLoading,
  } = useWorkspaceBoardAccessControlListUserRolesQuery({
    variables: {
      userId: user.id,
      workspaceBoardId,
    },
  });

  const isEditable =
    isEditing &&
    aclData?.workspaceBoardAccessControlListUserRoles.roles.some((assignedRole) =>
      [ObjectRole.ContentManager, ObjectRole.Contributor, ObjectRole.Manager, ObjectRole.Owner].includes(assignedRole),
    ) === true;

  // TODO: Optimally, this really should be in the base editor for the app so it's shared across all editors
  const onDebouncedSelectionUpdate = useDebouncedCallback((props: EditorEvents["selectionUpdate"]) => {
    const { editor } = props;

    // Generate plain text and HTML versions of the selection for the command context
    const { from, to, empty } = editor.state.selection;

    // TODO: Create a shared interface in Cookbook once we've locked down the selection schema
    const selection = {
      selectedTexts: [
        {
          plain: "",
          html: "",
        },
      ],
    };

    if (empty) {
      const selection: ICommandContext["selection"] = {};
      selection.shapeIds = tldrawEditor?.getSelectedShapeIds();
      selection.selectedTexts = [];
      CommandContext.patchCommandContext({
        selection,
      });
    } else {
      const plain = editor.state.doc.textBetween(from, to, " ");
      const docNodeType = getNodeType("doc", editor.schema);
      const doc = docNodeType.create(null, editor.state.selection.content().content).toJSON();
      const htmlContent = generateHTML(doc, editor.extensionManager.extensions);
      const html = htmlContent;

      selection.selectedTexts = [
        {
          plain,
          html,
        },
      ];

      // TODO: Parse the HTML and if it contains list items, then extract those out into an array under `selectedItems: Array<string>`
      CommandContext.patchCommandContext({ selection });
    }
  }, SELECTION_DEBOUNCE_TIME);

  React.useEffect(() => {
    setExtensionOptions({
      Placeholder: {
        placeholder: getPlaceholderFn(t),
      },
      ExportToWord: {
        exportHtmlToWord: getExportHtmlToWordFn(pageName, Config.apiGatewayHttpUrl, getUserToken),
      },
      Link: {
        LinkActions: [
          {
            action: "add-to-board",
            tooltip: t("Editor.Link.ActionsMenu.AddToBoard.Tooltip"),
            icon: PostAddOutlined,
            onClick: onAddIFrameToBoard,
            defaultDisabled: true,
            shouldBeDisabled: async (linkUrl: string) => {
              let result = false;
              try {
                const response = await fetchLinkProps({
                  variables: {
                    url: linkUrl,
                  },
                });
                result = response.data?.linkProps?.isEmbeddable === false;
              } catch (e) {
                result = true;
              }

              return result;
            },
          },
        ],
      },
    });
  }, [onAddIFrameToBoard, t, getUserToken, fetchLinkProps]);

  React.useEffect(() => {
    setCollaborativeConfig(_getCollaborativeConfig(user, provider, yDocumentFragment));
  }, [user, provider, yDocumentFragment]);

  React.useEffect(() => {
    if (extensionOptions) {
      setEditorOptions({
        // Disabled: Built-in autofocus goes to end of document due to async load of data
        autofocus: false,
        editorProps: {
          attributes: {
            class: getPageClassNames(pageSize, pageOrientation, pageMargin, "astra document shape"),
          },
        },
        extensions: [ExportToWord, ...getCommonEditorExtensions(extensionOptions, ["history"])],
        editable: isEditable,
        onSelectionUpdate: (args: EditorEvents["selectionUpdate"]) => {
          onSelectionUpdate?.(args);
          onDebouncedSelectionUpdate(args);
        },
        onUpdate,
      });
    }
  }, [/*Do not include: isEditable, */ extensionOptions, onDebouncedSelectionUpdate, onSelectionUpdate, onUpdate]);

  const optionsToUse = aclData ? editorOptions : undefined;
  const { collaborativeEditor, contentError } = useCollaborativeBoardDocumentEditor(optionsToUse, collaborativeConfig);
  useDoubtfireCleanup(collaborativeEditor);

  // TODO: Remove
  // @ts-ignore
  window.tiptap = collaborativeEditor;

  React.useEffect(() => {
    if (collaborativeEditor && !collaborativeEditor.isDestroyed) {
      // Clear all bookmarks
      let commandChain = collaborativeEditor.chain().clearAllBookmarks();

      // Activate any bookmarks that are currently active
      activeBookmarkIds.forEach((bookmarkId) => {
        commandChain.activateBookmark(bookmarkId);
      });

      commandChain.run();
    }
  }, [collaborativeEditor, activeBookmarkIds]);

  // Update the datastore editor when it changes
  React.useEffect(() => {
    onEditorChanged(collaborativeEditor);
  }, [collaborativeEditor]);

  let fixedToolbar = null;
  let actionMenus = null;
  if (collaborativeEditor && editorOptions) {
    fixedToolbar = ToolbarFactory.createToolbar(
      collaborativeEditor,
      {
        editorOptions,
        extensionOptions,
      },
      [
        "undo",
        "redo",
        "|",
        "textStyle",
        "|",
        "bold",
        "italic",
        "strike",
        "underline",
        "|",
        "textColor",
        "highlight",
        "|",
        "bulletList",
        "orderedList",
        "|",
        "textAlign",
        "|",
        "indent",
        "outdent",
        "collapsibleBlock",
        "|",
        "link",
        "table",
        "|",
        "search",
        "|",
        "exportToWord",
        "|",
        "command",
      ],
    );
    actionMenus = ActionMenuFactory.createActionMenu(
      collaborativeEditor,
      {
        editorOptions,
        extensionOptions,
      },
      ["imageActions", "linkActions"],
    );
  }

  React.useEffect(() => {
    if (collaborativeEditor && !collaborativeEditor.isDestroyed) {
      collaborativeEditor.setEditable(isEditable);

      if (isEditing) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            // Make sure we go to at least position 1. Zero will cause focus at the end of the document which we don't want as default/fallback
            collaborativeEditor.chain().focus(Math.max(1, activeSelection[1]), { scrollIntoView: true }).run();
          });
        });
      }
    }
  }, [collaborativeEditor, isEditing]);

  if (!yDocumentFragment) {
    return null;
  } else if (contentError) {
    return <div style={{ color: "red", position: "fixed", padding: "0.5in" }}>{t(`Editor.ContentError.${contentError}`)}</div>;
  } else {
    return (
      <>
        {isEditable && (
          // Don't change this to a fragment <></> as it will break actionMenus removal
          <div>
            <FixedToolbar sx={{ marginTop: "-50px", transform: "translateX(-50%)", left: "50%" }}>{fixedToolbar}</FixedToolbar>
            {actionMenus}
          </div>
        )}
        <EditorBase
          editor={collaborativeEditor}
          documentClassName="astra document shape"
          pageSize={pageSize}
          pageMargin={pageMargin}
          pageOrientation={pageOrientation}
        />
      </>
    );
  }
}
