// NOTE: Don't use the `from "node:path"` syntax here or WebPack will fail to polyfill this
import path from "path";
import { IStorageLocation } from "./IStorageLocation.js";
import {
  ATTACHMENT_DOWNLOAD_PROXY_PATH,
  DOCUMENT_IMAGE_DOWNLOAD_PROXY_PATH,
  WORKSPACE_ASSET_DOWNLOAD_PROXY_PATH,
  WORKSPACE_ATTACHMENT_DOWNLOAD_PROXY_PATH,
} from "./StorageRouteConstants.js";

// *********************************************
// Private constants
// *********************************************/
const S3_STORAGE_LOCATION_REGEX = /^s3:\/\/([^/]+)\/([^/]+)\/(.+)$/;

// *********************************************
// Public methods
// *********************************************/
/**
 * Gets the storage location for an attachment. Currently only supports S3.
 *
 * @param bucket The name of the S3 bucket.
 * @param organizationId The ID of the organization. This is used to enforce access permissions.
 * @param attachmentId The ID of the attachment. This is used to enforce access permissions.
 * @param filename The name of the file.
 *
 * @returns The standardized storage location URL.
 */
export function getAttachmentStorageLocationUrl(
  bucket: string,
  organizationId: string,
  attachmentId: string,
  filename: string,
): string {
  return `s3://${bucket}/${organizationId}/attachments/${attachmentId}/${filename}`;
}

/**
 * Gets the storage location for a source document file. Currently only supports S3.
 *
 * @param bucket The name of the S3 bucket.
 * @param organizationId The ID of the organization. This is used to enforce access permissions.
 * @param documentId The ID of the document. This is used to enforce access permissions.
 * @param filename The name of the document file.
 *
 * @returns The standardized storage location URL.
 */
export function getDocumentFileStorageLocationUrl(
  bucket: string,
  organizationId: string,
  documentId: string,
  filename: string,
): string {
  return `s3://${bucket}/${organizationId}/documents/${documentId}/${filename}`;
}

/**
 * Gets the storage location for a document image. Currently only supports S3.
 * @param bucket The name of the S3 bucket.
 * @param organizationId The ID of the organization. This is used to enforce access permissions.
 * @param documentId The ID of the document. This is used to enforce access permissions.
 * @param filename The name of the image file.
 *
 * @returns The standardized storage location URL.
 */
export function getDocumentImageStorageLocationUrl(
  bucket: string,
  organizationId: string,
  documentId: string,
  filename: string,
): string {
  return `s3://${bucket}/${organizationId}/documents/${documentId}/images/${filename}`;
}

/**
 * Gets the storage location for a subscription content. Currently only supports S3.
 *
 * @param bucket The name of the S3 bucket.
 * @param organizationId The ID of the organization. This is used to enforce access permissions.
 * @param subscriptionSource The ID of the workspace. This is used to enforce access permissions.
 * @param workspaceBoardId The ID of the workspace board. This is used to organize content in the storage location.
 * @param filename The name of the file.
 *
 * @returns The standardized storage location URL.
 */
export function getSubscriptionContentStorageLocationUrl(
  bucket: string,
  organizationId: string,
  subscriptionSource: string,
  filename: string,
): string {
  return `s3://${bucket}/${organizationId}/subscriptions/${subscriptionSource}/${filename}`;
}

export function getSubscriptionSourceFromStorageLocation(storageLocation: IStorageLocation): string {
  // Example full path: ${organizationId}/subscriptions/${subscriptionSource}/${filename} where filename is optional
  const pathParts = storageLocation.fullPath.split("/");
  let result = "";

  if (pathParts.length < 3) {
    throw new Error(`Invalid storage location path: ${storageLocation.fullPath}`);
  }
  result = pathParts[2];
  if (result.endsWith("/")) {
    result = result.slice(0, -1);
  }

  return result;
}

/**
 * Gets the storage location for a workspace content. Currently only supports S3.
 *
 * Note: No files are currently stored directly in the workspace content folder.
 * This method can be used to get the path to the workspace content folder for
 * actions such as deleting the whole workspace and all its contents, including board content.
 *
 *
 * @param bucket The name of the S3 bucket.
 * @param organizationId The ID of the organization. This is used to enforce access permissions.
 * @param workspaceId The ID of the workspace. This is used to enforce access permissions and create the path.
 * @param filename The name of the file.
 *
 * @returns The standardized storage location URL.
 */
export function getWorkspaceStorageLocationUrl(
  bucket: string,
  organizationId: string,
  workspaceId: string,
  filename: string,
): string {
  return `s3://${bucket}/${organizationId}/workspaces/${workspaceId}/${filename}`;
}

/**
 * Gets the storage location for a workspace board assets. Currently only supports S3.
 *
 * @param bucket The name of the S3 bucket.
 * @param organizationId The ID of the organization. This is used to enforce access permissions.
 * @param workspaceId The ID of the workspace. This is used to enforce access permissions.
 * @param workspaceBoardId The ID of the workspace board. This is used to organize content in the storage location.
 * @param filename The name of the file.
 *
 * @returns The standardized storage location URL.
 */
export function getWorkspaceBoardAssetStorageLocationUrl(
  bucket: string,
  organizationId: string,
  workspaceId: string,
  workspaceBoardId: string,
  filename: string,
): string {
  return `s3://${bucket}/${organizationId}/workspaces/${workspaceId}/workspaceBoards/${workspaceBoardId}/assets/${filename}`;
}

/**
 * Gets the storage location for a workspace attachments. Currently only supports S3.
 *
 * @param bucket The name of the S3 bucket.
 * @param organizationId The ID of the organization. This is used to enforce access permissions.
 * @param workspaceId The ID of the workspace. This is used to enforce access permissions.
 * @param workspaceAttachmentId The ID of the workspace attachment. This is used to organize content in the storage location.
 * @param filename The name of the file.
 *
 * @returns The standardized storage location URL.
 */
export function getWorkspaceAttachmentStorageLocationUrl(
  bucket: string,
  organizationId: string,
  workspaceId: string,
  workspaceAttachmentId: string,
  filename: string,
): string {
  return `s3://${bucket}/${organizationId}/workspaces/${workspaceId}/attachments/${workspaceAttachmentId}/${filename}`;
}

/**
 * Gets the storage location for a workspace attachment image. Currently only supports S3.
 *
 * @param bucket The name of the S3 bucket.
 * @param organizationId The ID of the organization. This is used to enforce access permissions.
 * @param workspaceId The ID of the workspace. This is used to enforce access permissions.
 * @param workspaceAttachmentId The ID of the workspace attachment. This is used to organize content in the storage location.
 * @param imageFilename The name of the image file.
 *
 * @returns The standardized storage location URL.
 */
export function getWorkspaceAttachmentImageStorageLocationUrl(
  bucket: string,
  organizationId: string,
  workspaceId: string,
  workspaceAttachmentId: string,
  imageFilename: string,
): string {
  return `s3://${bucket}/${organizationId}/workspaces/${workspaceId}/attachments/${workspaceAttachmentId}/images/${imageFilename}`;
}

/**
 * Parses a standardized storage location path, such as s3://bucket-name/organization-id/path/to/file.ext
 * to an object with the parts separated out.
 *
 * @param url The URL to parse.
 *
 * @returns An object with the parts of the storage location.
 *
 * @throws An error if the storage location is invalid.
 */
export function parseStorageLocationUrl(url: string): IStorageLocation {
  if (url.startsWith("s3://")) {
    const matches = S3_STORAGE_LOCATION_REGEX.exec(url);
    if (!matches) {
      throw new Error(`Invalid storage location: ${url}`);
    }

    let filename = path.basename(matches[3]);

    // Allow passing in a URL without filename when the URL ends with a slash
    if (url.endsWith("/")) {
      filename = "";
    }

    return {
      bucket: matches[1],
      extension: path.extname(filename),
      filename,
      name: path.parse(filename).name,
      organizationId: matches[2],
      fullPath: `${matches[2]}/${matches[3]}`,
      path: matches[3],
      url: url,
    };
  } else {
    throw new Error(`Invalid storage location URL: ${url}`);
  }
}

/**
 * Converts a storage location for an attachment to a URL for downloading the attachment from the given host.
 *
 * @param hostUrl The host URL to use, usually either the API gateway.
 * @param storageLocationUrl The storage location to convert.
 *
 * @returns The URL for downloading the attachment.
 */
export function attachmentStorageLocationToDownloadUrl(hostUrl: string, storageLocationUrl: IStorageLocation): string {
  return `${hostUrl}/${ATTACHMENT_DOWNLOAD_PROXY_PATH}/${storageLocationUrl.path}`;
}

/**
 * Converts a storage location for a document image to a URL for downloading the image from the given host.
 * Note: This URL contains the organizationId to enable organization access verification.
 *
 * @param hostUrl The host URL to use, usually the asset host.
 * @param storageLocationUrl The storage location to convert.
 *
 * @returns The URL for downloading the document image.
 */
export function documentImageStorageLocationToDownloadUrl(hostUrl: string, storageLocationUrl: IStorageLocation): string {
  // NOTE: Images need full path, including organizationId, to enable organization access verification
  return `${hostUrl}/${DOCUMENT_IMAGE_DOWNLOAD_PROXY_PATH}/${storageLocationUrl.fullPath}`;
}

/**
 * Converts a storage location for a WorkspaceBoard asset to a URL for downloading the asset from the given host.
 *
 * @param hostUrl The host URL to use, usually the API gateway.
 * @param storageLocationUrl The storage location to convert.
 *
 * @returns The URL for downloading the asset.
 */
export function workspaceBoardAssetStorageLocationToDownloadUrl(hostUrl: string, storageLocationUrl: IStorageLocation): string {
  return `${hostUrl}/${WORKSPACE_ASSET_DOWNLOAD_PROXY_PATH}/${storageLocationUrl.path}`;
}

/**
 * Converts a storage location for a WorkspaceAttachment to a URL for downloading the asset from the given host.
 *
 * @param hostUrl The host URL to use, usually the API gateway.
 * @param storageLocationUrl The storage location to convert.
 *
 * @returns The URL for downloading the asset.
 */
export function workspaceAttachmentStorageLocationToDownloadUrl(hostUrl: string, storageLocationUrl: IStorageLocation): string {
  return `${hostUrl}/${WORKSPACE_ATTACHMENT_DOWNLOAD_PROXY_PATH}/${storageLocationUrl.path}`;
}

/**
 * Converts a storage location for a WorkspaceAttachment image to a URL for downloading the image from the given host.
 *
 * @param hostUrl The host URL to use, usually the API gateway.
 * @param storageLocationUrl The storage location to convert.
 *
 * @returns The URL for downloading the image.
 */
export function workspaceAttachmentImageStorageLocationToDownloadUrl(
  hostUrl: string,
  storageLocationUrl: IStorageLocation,
): string {
  return `${hostUrl}/${WORKSPACE_ATTACHMENT_DOWNLOAD_PROXY_PATH}/${storageLocationUrl.fullPath}`;
}
