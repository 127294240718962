import { CONFIG_KEY_ORGANIZATION_PREFERENCES } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import { useValue } from "@tldraw/tldraw";

import { useGetConfigDataQuery } from "GraphQL/Generated/Apollo";

/**
 * Hook to represent the app level isChatEnabled config.
 */
export function useIsChatEnabled() {
  const { data: appConfigDataResult } = useGetConfigDataQuery({
    variables: {
      key: CONFIG_KEY_ORGANIZATION_PREFERENCES,
      organizationId: useAuthUser()?.tenantId,
    },
  });
  const isChatEnabled = useValue(
    "isChatEnabled",
    () => {
      return JSON.parse(appConfigDataResult?.Config?.data || "{}").isChatEnabled;
    },
    [appConfigDataResult],
  );
  return isChatEnabled;
}
