import { IDateRangeLimits, IDateRange, IDateRangeOption } from "@bigpi/cookbook";
import {
  DateRange,
  DateRangePicker,
  DateRangePickerSlotsComponentsProps,
  DateRangeValidationError,
  LocalizationProvider,
  PickerChangeHandlerContext,
  PickersShortcutsItem,
  PickersShortcutsProps,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { Box, SxProps, Theme, List, ListItem, Chip } from "@mui/material";
import { useValue } from "@tldraw/tldraw";

import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { TFunction } from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// css
import "./DataDateRangePicker.css";

// Extends dayjs with utc plugin
dayjs.extend(utc);

/*********************************
 * Private constants/methods
 *********************************/
export interface DataDateRangePickerProps {
  shortcutItems?: Array<IDateRangeOption>;
  dateRange?: IDateRangeLimits;
  field?: string;
  value?: IDateRange;
  shortcutValue?: string;
  isDisabled: boolean;
  label: string;
  localeText?: any;
  onDateRangeChange: (dateRange: IDateRange, shortcut?: string) => void;
  dateRangePickerSx?: SxProps<Theme>;
  dateRangePickerSlotProps?: DateRangePickerSlotsComponentsProps<Dayjs>;
}

/*********************************
 * Component
 *********************************/
export const DataDateRangePicker: React.FC<DataDateRangePickerProps> = (props) => {
  const {
    shortcutItems = [],
    dateRange,
    value,
    isDisabled,
    label,
    localeText,
    onDateRangeChange,
    dateRangePickerSx,
    dateRangePickerSlotProps,
    shortcutValue,
  } = props;

  const { t } = useTranslation();

  let dateRangeValue = dateRange;

  const [formattedShortcutItems, setFormattedShortCutItems] = React.useState<Array<PickersShortcutsItem<DateRange<Dayjs>>>>([
    ...getShortcutItems(shortcutItems, dayjs.utc(dateRangeValue?.min), dayjs.utc(dateRangeValue?.max)),
    getResetShortcutItem(t),
  ]);
  // Map of shortcut item value to label and vice versa
  const shortcutItemsValueLabelMapRef = React.useRef<Record<string, string>>({});

  useEffect(() => {
    setFormattedShortCutItems([
      ...getShortcutItems(shortcutItems, dayjs.utc(dateRangeValue?.min), dayjs.utc(dateRangeValue?.max)),
      getResetShortcutItem(t),
    ]);
  }, [t, dateRangeValue?.min, dateRangeValue?.max, shortcutItems]);

  useEffect(() => {
    const valueLabelMap = shortcutItems.reduce(
      (acc, item) => {
        acc[item.title] = item.id;
        acc[item.id] = item.title;
        return acc;
      },
      {} as Record<string, string>,
    );
    shortcutItemsValueLabelMapRef.current = valueLabelMap;
  }, [shortcutItems]);

  const shortcutValueLabel = useValue(
    "shortcutValueLabel",
    () => {
      return shortcutItemsValueLabelMapRef.current[shortcutValue || ""];
    },
    [shortcutItemsValueLabelMapRef.current, shortcutValue],
  );

  return (
    <Box sx={{ display: "flex" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          sx={{ width: 300, ...dateRangePickerSx }}
          label={label}
          disabled={isDisabled}
          localeText={localeText}
          minDate={dayjs.utc(dateRangeValue?.min)}
          maxDate={dayjs.utc(dateRangeValue?.max)}
          onChange={onDateChange}
          slots={{
            field: SingleInputDateRangeField,
            shortcuts: CustomRangeShortcuts,
          }}
          slotProps={{
            shortcuts: {
              items: formattedShortcutItems,
              // @ts-expect-error 'selectedLabel' doesn't exists in slot props (Active issue for this: https://github.com/mui/mui-x/issues/9775)
              selectedLabel: shortcutValueLabel,
            },
            ...dateRangePickerSlotProps,
          }}
          value={[value && value.from ? dayjs.utc(value.from) : null, value && value.to ? dayjs.utc(value.to) : null]}
        />
      </LocalizationProvider>
    </Box>
  );

  function onDateChange(dates: DateRange<Dayjs> | null, ctx: PickerChangeHandlerContext<DateRangeValidationError>) {
    const shortcutValue = ctx.shortcut?.label;
    const from = dates ? dates[0] : null;
    const to = dates ? dates[1] : null;

    const dateValues = {
      from: from ? (from.isValid() ? from.toISOString() : "") : "",
      to: to ? (to.isValid() ? to.toISOString() : "") : "",
    };
    onDateRangeChange(dateValues, shortcutItemsValueLabelMapRef.current[shortcutValue || ""]);
  }
};

/*******************************
 * Private methods
 *******************************/

/**
 * Custom shortcut items for the date range picker
 *
 * @param customDateShortcuts Custom shortcuts to be added to the date range picker
 * @param minDate Min date to be used in the date range picker
 * @param maxDate Max date to be used in the date range picker
 * @returns
 */
function getShortcutItems(customDateShortcuts: Array<IDateRangeOption> | [], minDate: Dayjs, maxDate: Dayjs) {
  if (customDateShortcuts.length === 0) {
    return [];
  }
  return customDateShortcuts.map((shortcut: IDateRangeOption) => {
    return {
      label: shortcut.title,
      id: shortcut.id,
      getValue: () => {
        let startDate = dayjs.utc(shortcut.startDate);
        let endDate = dayjs.utc(shortcut.endDate);
        if (startDate!.isBefore(minDate)) {
          startDate = minDate;
        }
        if (endDate!.isAfter(maxDate)) {
          endDate = maxDate;
        }
        return [startDate, endDate] as DateRange<Dayjs>;
      },
    };
  });
}

/**
 * Get reset shortcut item
 *
 * @param t Translation function
 * @returns
 */
function getResetShortcutItem(t: TFunction): PickersShortcutsItem<DateRange<Dayjs>> {
  return {
    label: t("Components.Charts.DateShortcuts.Reset"),
    getValue: () => [null, null],
  };
}

/**
 * Custom range shortcuts component to highlight the selected shortcut
 *
 * @param props Props for the custom range shortcuts
 * @returns
 */
function CustomRangeShortcuts(props: PickersShortcutsProps<DateRange<Dayjs>> & { selectedLabel?: string }) {
  const { items, onChange, isValid, changeImportance = "accept", selectedLabel, className, ...other } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = React.useMemo(() => {
    return items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue, changeImportance, item);
        },
        disabled: !isValid(newValue),
      };
    });
  }, [items]);
  return (
    <List
      dense
      sx={[
        {
          maxHeight: 400,
          maxWidth: 200,
          overflow: "auto",
        },
        ...(Array.isArray(other.sx) ? other.sx : [other.sx]),
      ]}
      className={className}
    >
      {resolvedItems.map((item, index) => {
        return (
          <ListItem key={index}>
            <Chip {...item} color={item.label === selectedLabel ? "primary" : "default"} />
          </ListItem>
        );
      })}
    </List>
  );
}
