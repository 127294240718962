import AttachmentAccessControlListEditor from "Components/AccessControlList/AttachmentAccessControlListEditor";
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useAttachmentQuery } from "GraphQL/Generated/Apollo";

export interface AttachmentAccessControlListDialogProps {
  open: boolean;
  onClose: () => void;
  attachmentId: string;
}

export function AttachmentAccessControlListDialog(props: AttachmentAccessControlListDialogProps) {
  const { t } = useTranslation();
  const { onClose, open, attachmentId } = props;
  const { data, loading, error } = useAttachmentQuery({ variables: { id: attachmentId } });
  const name = data?.attachment?.name || "";

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth={true} disableEscapeKeyDown={false}>
      <DialogTitle variant="h5">
        <Trans i18nKey="Components.AttachmentAccessControlListDialog.Title">Share "{{ name }}"</Trans>
      </DialogTitle>
      <DialogContent>
        <AttachmentAccessControlListEditor attachmentId={attachmentId} />
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AttachmentAccessControlListDialog;
