import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Key from "@mui/icons-material/Key";
import LabelIcon from "@mui/icons-material/Label";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import { Box, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip, Divider } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import HasWorkspaceRole from "Components/HasWorkspaceRole/HasWorkspaceRole";
import { ObjectRole, useWorkspaceQuery } from "GraphQL/Generated/Apollo";
import { DeleteWorkspaceBoardDialog } from "./DeleteWorkspaceBoardDialog";
import EditWorkspaceBoardDescriptionDialog from "./EditWorkspaceBoardDescriptionDialog";
import MakeDefaultWorkspaceBoardDialog from "./MakeDefaultWorkspaceBoardDialog";
import { RenameWorkspaceBoardDialog } from "./RenameWorkspaceBoardDialog";
import { UpdateWorkspaceBoardTagsDialog } from "./UpdateWorkspaceBoardTagsDialog";

export interface IWorkspaceBoardListMenuProps {
  workspaceBoardId: string;
  workspaceId: string;
}

export default function WorkspaceBoardListMenu(props: IWorkspaceBoardListMenuProps) {
  const { workspaceId, workspaceBoardId } = props;

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);
  const [updateWorkspaceTagsDialogOpen, setUpdateWorkspaceTagsDialogOpen] = React.useState(false);
  const [makeDefaultWorkspaceBoardDialogOpen, setMakeDefaultWorkspaceBoardDialogOpen] = React.useState(false);
  const [editDescriptionDialogOpen, setEditDescriptionDialogOpen] = React.useState(false);

  const open = Boolean(anchorEl);

  const { data: workspaceData } = useWorkspaceQuery({
    variables: { id: workspaceId },
  });
  const defaultWorkspaceBoardId = workspaceData?.workspace?.defaultWorkspaceBoard?.id;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("Global.Action.Actions") || ""}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "workspace-board-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 24,
              height: 24,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <HasWorkspaceRole workspaceId={workspaceId} roles={[ObjectRole.Manager, ObjectRole.Owner]}>
          <MenuItem onClick={() => setRenameDialogOpen(true)}>
            <ListItemIcon>
              <DriveFileRenameOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.Rename")} />
          </MenuItem>

          <MenuItem onClick={() => setUpdateWorkspaceTagsDialogOpen(true)}>
            <ListItemIcon>
              <LabelIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.UpdateBoardTags")} />
          </MenuItem>

          <MenuItem onClick={() => setEditDescriptionDialogOpen(true)}>
            <ListItemIcon>
              <DescriptionIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.EditDescription")} />
          </MenuItem>

          {defaultWorkspaceBoardId && defaultWorkspaceBoardId !== workspaceBoardId && (
            <>
              <MenuItem onClick={() => setMakeDefaultWorkspaceBoardDialogOpen(true)}>
                <ListItemIcon>
                  <Key fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t("Global.Action.MakeDefaultBoard")} />
              </MenuItem>

              <Divider />

              <MenuItem onClick={() => setDeleteDialogOpen(true)}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: "danger.main" }} primary={t("Global.Action.Delete")} />
              </MenuItem>
            </>
          )}
        </HasWorkspaceRole>
      </Menu>
      {deleteDialogOpen && (
        <DeleteWorkspaceBoardDialog
          workspaceBoardId={workspaceBoardId}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        />
      )}
      {renameDialogOpen && (
        <RenameWorkspaceBoardDialog
          workspaceBoardId={workspaceBoardId}
          open={renameDialogOpen}
          onClose={() => setRenameDialogOpen(false)}
        />
      )}
      {updateWorkspaceTagsDialogOpen && (
        <UpdateWorkspaceBoardTagsDialog
          workspaceBoardId={workspaceBoardId}
          open={updateWorkspaceTagsDialogOpen}
          onClose={() => setUpdateWorkspaceTagsDialogOpen(false)}
        />
      )}
      {makeDefaultWorkspaceBoardDialogOpen && (
        <MakeDefaultWorkspaceBoardDialog
          workspaceId={workspaceId}
          workspaceBoardId={workspaceBoardId}
          open={makeDefaultWorkspaceBoardDialogOpen}
          onClose={() => setMakeDefaultWorkspaceBoardDialogOpen(false)}
        />
      )}
      {editDescriptionDialogOpen && (
        <EditWorkspaceBoardDescriptionDialog
          workspaceBoardId={workspaceBoardId}
          open={editDescriptionDialogOpen}
          onClose={() => setEditDescriptionDialogOpen(false)}
        />
      )}
    </React.Fragment>
  );
}
