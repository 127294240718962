import { TopicDiscussionInNewsArticleAnalysisRecordTypes } from "../Enums/TopicDiscussionInNewsArticleAnalysisRecordTypes.js";

// The maximum allowed length of a workspace tag
export const WORKSPACE_TAG_MAX_LENGTH = 30;

// The tag used to identify a workspace template
export const WORKSPACE_TEMPLATE_TAG = "template";

// The default topic discussion in news article analysis type
export const DEFAULT_TOPIC_DISCUSSION_IN_NEWS_ARTICLE_ANALYSIS_TYPE =
  TopicDiscussionInNewsArticleAnalysisRecordTypes.ElectricVehicles;

// The mention class name used in chat message editor
export const MENTION_CLASS_NAME = "mention";

export const UNKNOWN_USER_MENTION_CLASS_NAME = "unknown-user-mention";

export const PRIVATE_MENTION_CLASS_NAME = "private-mention";

export const UNKNOWN_PRIVATE_USER_MENTION_CLASS_NAME = "unknown-private-user-mention";

export const MISSING_USER_CLASS_NAME = "missing-user";

export const ONE_MB = 1024 * 1024;

export const WORKSPACE_ATTACHMENT_UPLOAD_MAX_FILE_SIZE = 50 * ONE_MB; // 50 MB
