import { Button, Stack } from "@mui/material";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { linkToId, RoutePaths } from "RoutePaths";
import { useCreateUserGroupMutation, useUpdateUserGroupMutation } from "GraphQL/Generated/Apollo";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface UserGroupFormProps {
  userGroup?: {
    id: string;
    name: string;
  } | null;
}

export default function UserGroupForm(props: UserGroupFormProps) {
  const { t } = useTranslation();
  const [createUserGroup] = useCreateUserGroupMutation();
  const [updateUserGroup] = useUpdateUserGroupMutation();
  const { userGroup } = props;
  const navigate = useNavigate();

  const defaultValues = {
    name: userGroup?.name || "",
  };

  return (
    <FormContainer
      defaultValues={defaultValues}
      onSuccess={(data) => {
        const id = userGroup?.id ?? "new";
        // TODO: Error handling
        if (id === "new") {
          createUserGroup({ variables: { input: data } }).then((result) => {
            const newItem = result.data!.createUserGroup;
            if (newItem) {
              navigate(linkToId("userGroup", newItem.id), { replace: true });
            }
          });
        } else {
          updateUserGroup({
            variables: {
              input: {
                ...data,
                id,
              },
            },
          }).then(() => {
            navigate(RoutePaths.userGroups.path);
          });
        }
      }}
    >
      <Stack direction={"column"} sx={{ maxWidth: "400px" }}>
        <TextFieldElement name="name" label="Name" required />
      </Stack>
      <br />
      <Button type={"submit"} variant={"contained"} color={"primary"}>
        {t("Global.Action.Save")}
      </Button>
    </FormContainer>
  );
}
