import { CommandActionType, ICommand, ICommandRequest, ICommandResponse } from "@bigpi/cookbook";
import { useAuth } from "@frontegg/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidV4 } from "uuid";

import { OnCommandRequestEventArgs } from "Components/CommandManagers/CommandExecutor";
import { CommandsDialog } from "Components/Commands/CommandsDialog";
import {
  CommandPingMutationVariables,
  useCommandPingMutation,
  useExecuteCommandMutation,
  useOnCommandResponseSubscription,
} from "GraphQL/Generated/Apollo";
import { CommandConnectionActiveVar } from "GraphQL/AuthenticatedApolloProvider";
import { useCommandExecutor } from "./useCommandExecutor";

const PING_INTERVAL = 10 * 1000;
const PING_TIMEOUT = 15 * 1000;
const MAX_PING_RETRY_COUNT = 5;

const MAX_RETRY_COUNT = 5;
const PING_COMMAND_TEMPLATE: CommandPingMutationVariables = {
  input: {
    // callerData: undefined,
    commandContext: {
      application: {},
      organization: {},
      user: {},
      session: [],
      selection: {},
    },
    commandId: CommandActionType.PingAction,
    outputTemplate: "",
    requestId: "",
    sessionId: "",
  },
};

/**
 * Props for the CommandManager component.
 */
type ICommandManagerProps = {
  children?: React.ReactNode;
};

/**
 * Handles command response subscriptions for the whole application.
 *
 * @param props The component props.
 *
 * @returns A component containing passed in children and the common command trigger element.
 */
export function CommandManager(props: ICommandManagerProps) {
  const [retryCount, setRetryCount] = useState(0);
  const pingRetryCount = useRef(0);
  const [isFatalConnectionError, setIsFatalConnectionError] = useState(false);
  const [pingRequestId, setPingRequestId] = useState<string | undefined>(undefined);
  const pingTimer = useRef<NodeJS.Timeout | undefined>(undefined);
  const pingTimeoutTimer = useRef<NodeJS.Timeout | undefined>(undefined);
  const [executePingCommand] = useCommandPingMutation();
  const { isAuthenticated, user } = useAuth();
  const commandExecutor = useCommandExecutor();
  const [executeCommandHook] = useExecuteCommandMutation();

  const onRunCommand = useCallback(
    async (command: ICommand, commandRequest: Partial<ICommandRequest>) => {
      // Call the executor to run the command and trigger the lifecycle events
      await commandExecutor.executeCommand(command, commandRequest);
    },
    [commandExecutor],
  );

  const onCommandRequest = useCallback(
    async (data: OnCommandRequestEventArgs) => {
      const { commandRequest } = data;

      // Send the final command request to the server to execute the command
      await executeCommandHook({
        variables: {
          input: {
            callerData: commandRequest.callerData,
            commandId: commandRequest.commandId,
            commandContext: data.commandRequest.commandContext,
            deduplicationId: commandRequest.deduplicationId,
            outputTemplate: commandRequest.outputTemplate,
            requestId: commandRequest.requestId,
            sessionId: commandRequest.sessionId,
          },
        },
      });
    },
    [executeCommandHook],
  );

  // Add our custom handler for the onCommandRequest event
  useEffect(() => {
    commandExecutor.on("commandRequest", onCommandRequest);
    return () => {
      commandExecutor.off("commandRequest", onCommandRequest);
    };
  }, [commandExecutor, onCommandRequest]);

  const { data, loading, error } = useOnCommandResponseSubscription({
    variables: {
      sessionId: commandExecutor.sessionId,
    },
    shouldResubscribe: true,
    onData: (data) => {
      setRetryCount(0);
      CommandConnectionActiveVar(true);
    },
    onError: (error) => {
      // Ignore if the user is not authenticated
      if (isAuthenticated && user) {
        console.error("CommandManager, onError", error);
        if (retryCount < MAX_RETRY_COUNT) {
          console.log(`Resetting session ID and retrying (${retryCount + 1})`);
          // Change the session ID to force a re-subscription
          commandExecutor.rotateSessionId();

          // Increment the retry count
          setRetryCount(retryCount + 1);
        } else {
          console.error("Maximum command connection retry count reached, not retrying");
          setIsFatalConnectionError(true);
        }
      }
    },
  });

  /**
   * Check for pong when we receive a command response.
   */
  useEffect(() => {
    if (data) {
      if (data.commandResponse?.requestId) {
        // If the response is a pong then clear the ping timeout timer
        if (data.commandResponse.requestId === pingRequestId) {
          if (pingTimeoutTimer.current) {
            clearTimeout(pingTimeoutTimer.current);
          }

          // Restart ping timer
          if (pingTimer.current) {
            clearTimeout(pingTimer.current);
          }
          pingTimer.current = setTimeout(pingCommand, PING_INTERVAL);
          pingRetryCount.current = 0;
        }
      }
    }
  }, [data]);

  /**
   * Handle ping timeouts.
   */
  const pingTimeout = useCallback(() => {
    // If we get here then we haven't received a ping response in time
    console.error("Ping timeout, resetting command connection and session ID");
    CommandConnectionActiveVar(false);
    commandExecutor.rotateSessionId();

    // Restart ping timers
    if (pingTimer.current) {
      clearTimeout(pingTimer.current);
    }
    if (pingTimeoutTimer.current) {
      clearTimeout(pingTimeoutTimer.current);
    }
    pingTimer.current = setTimeout(pingCommand, PING_INTERVAL);
  }, []);

  /**
   * Ping the command server.
   */
  const pingCommand = useCallback(async () => {
    // Check if we have a valid user session
    if (!isAuthenticated || !user) {
      return;
    }

    const newRequestId = uuidV4();
    const variables: CommandPingMutationVariables = {
      ...PING_COMMAND_TEMPLATE,
    };
    variables.input.requestId = newRequestId;
    variables.input.sessionId = commandExecutor.sessionId;

    // Save so we can check for a pong response
    setPingRequestId(newRequestId);

    try {
      await executePingCommand({
        variables,
        fetchPolicy: "no-cache",
      });

      // Start ping timeout timer to wait for response
      if (pingTimeoutTimer.current) {
        clearTimeout(pingTimeoutTimer.current);
      }
      pingTimeoutTimer.current = setTimeout(pingTimeout, PING_TIMEOUT);
    } catch (error) {
      // Ignore if the user is not authenticated
      if (isAuthenticated && user) {
        console.error("Error pinging command server.", error);
        CommandConnectionActiveVar(false);

        if (pingRetryCount.current < MAX_PING_RETRY_COUNT) {
          console.log(`Resetting session ID and retrying ping (${pingRetryCount.current + 1})...`);
          // Rotate session ID so that the subscription is re-created
          commandExecutor.rotateSessionId();

          // Restart ping timers
          if (pingTimer.current) {
            clearTimeout(pingTimer.current);
          }
          if (pingTimeoutTimer.current) {
            clearTimeout(pingTimeoutTimer.current);
          }

          // Increment the retry count
          pingRetryCount.current = pingRetryCount.current + 1;

          pingTimer.current = setTimeout(pingCommand, PING_INTERVAL);
        }
      } else {
        console.error("Maximum command connection ping retry count reached, not retrying");
        setIsFatalConnectionError(true);
      }
    }
  }, [CommandConnectionActiveVar, commandExecutor.sessionId, executePingCommand, pingRetryCount]);

  /**
   * Start ping timer when component is mounted. Stop timers and reset sessionId when component is unmounted.
   */
  useEffect(() => {
    // Start or restart ping timer
    if (pingTimer.current) {
      clearTimeout(pingTimer.current);
    }
    const timerId = setTimeout(pingCommand, PING_INTERVAL);
    pingTimer.current = timerId;

    return () => {
      if (pingTimer.current) {
        clearTimeout(pingTimer.current);
        pingTimer.current = undefined;
      }
      if (pingTimeoutTimer.current) {
        clearTimeout(pingTimeoutTimer.current);
        pingTimeoutTimer.current = undefined;
      }

      // Attempt to stop the original timer - this can happen if we're called before state is updated
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, []);

  // Handle incoming command responses
  useEffect(() => {
    if (data && data.commandResponse && data.commandResponse?.data !== "pong") {
      const eventData = {
        commandResponse: data.commandResponse,
      };
      // Response lifecycle events
      commandExecutor.raiseBeforeCommandResponse(eventData);

      commandExecutor.raiseCommandResponse(eventData);

      commandExecutor.raiseAfterCommandResponse(eventData);
    }
  }, [data]);

  return (
    <>
      {props.children}
      <CommandsDialog isFatalConnectionError={isFatalConnectionError} onRunCommand={onRunCommand} />
    </>
  );
}
