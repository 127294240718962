import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { useDeleteWorkspaceAttachmentMutation, useWorkspaceAttachmentQuery } from "GraphQL/Generated/Apollo";

export interface IDeleteWorkspaceAttachmentDialogProps {
  open: boolean;
  workspaceAttachmentId: string;
  onClose: () => void;
}

export function DeleteWorkspaceAttachmentDialog(props: IDeleteWorkspaceAttachmentDialogProps) {
  const { open, workspaceAttachmentId } = props;

  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = React.useState("");

  const [deleteWorkspaceAttachment] = useDeleteWorkspaceAttachmentMutation();
  const { data, loading, error } = useWorkspaceAttachmentQuery({ variables: { id: workspaceAttachmentId } });

  function onClose() {
    setErrorMessage("");
    props.onClose();
  }

  function onDelete(): void {
    deleteWorkspaceAttachment({
      variables: {
        input: {
          id: workspaceAttachmentId,
        },
      },
      refetchQueries: ["WorkspaceAttachments", "WorkspaceAttachmentAggregate", "Workspaces"],
    })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(t("Components.DeleteWorkspaceAttachmentDialog.Error"));
      });
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
        <DialogTitle variant="h5">
          {t("Components.DeleteWorkspaceAttachmentDialog.Title", { name: data?.workspaceAttachment?.name || "" })}
        </DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <DialogContentText>{t("Components.DeleteWorkspaceAttachmentDialog.Tip")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onDelete} color="danger">
            {t("Global.Action.DeletePermanently")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
