import * as React from "react";
import { ObjectRole } from "GraphQL/Generated/Apollo";
import { useAuthUser } from "@frontegg/react";
import { useAttachmentAccessControlListUserRolesQuery } from "GraphQL/Generated/Apollo";

export interface HasAttachmentRoleProps {
  children: React.ReactNode;
  roles: Array<ObjectRole>;
  attachmentId: string;
}

export const HasAttachmentRole = (props: HasAttachmentRoleProps) => {
  const { children, roles, attachmentId } = props;
  const user = useAuthUser();
  const { data, error, loading } = useAttachmentAccessControlListUserRolesQuery({
    variables: {
      userId: user.id,
      attachmentId,
    },
  });

  if (data?.attachmentAccessControlListUserRoles.roles.some((assignedRole) => roles.includes(assignedRole as ObjectRole))) {
    return <>{children}</>;
  }
  return <></>;
};

export default HasAttachmentRole;
