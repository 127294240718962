import { IBarChartShape, barChartShapeProps, getBarChartShapeDefaultProps, barChartShapeMigrations } from "@bigpi/tl-schema";
import { HTMLContainer, useValue, Migrations } from "@tldraw/tldraw";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { AutoResizingContainer } from "Components/AutoResizingContainer/AutoResizingContainer";
import { IAnalysisShapeData, useBoardDatastore } from "BoardComponents/BoardDatastore";
import { BoxBaseUtil } from "BoardComponents/BaseShapes/BoxBaseUtil";
import { useIsInteracting } from "BoardComponents/Tools";
import { useShapeEvents } from "BoardComponents/useShapeEvents";
import BarChart from "Components/Charting/Charts/BarChart";
import { BarChartLoader } from "Components/Charting/Loaders/BarChartLoader";
import { CHART_DEFAULT_DIMENSIONS } from "Utils/ChartUtils";
import { DataUtils } from "Utils/DataUtils";
// css
import "./BarChartShape.css";

/**
 * Padding to add around the chart to ensure that the warning message is visible and isn't on the edge of the chart.
 */
const WARNING_PADDING = 20;

// *********************************************
// Shape Util
// *********************************************/
/**
 * Generator for generic bubble chart shapes that support grouping.
 */
export class BarChartUtil extends BoxBaseUtil<IBarChartShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "barChart";

  static props = barChartShapeProps;

  static migrations: Migrations = barChartShapeMigrations;

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override canBind = (shape: IBarChartShape) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: IBarChartShape) => true;

  /**
   * @inheritdoc
   */
  override canResize = (shape: IBarChartShape) => false;

  /**
   * @inheritdoc
   */
  override canScroll = (shape: IBarChartShape) => false;

  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: IBarChartShape) => false;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): IBarChartShape["props"] {
    return getBarChartShapeDefaultProps();
  }

  /**
   * @inheritdoc
   */
  override indicator(shape: IBarChartShape) {
    const { id } = shape;
    const isEditing = this.editor.getCurrentPageState().editingShapeId === id;
    return <rect width={shape.props.w} height={shape.props.h} stroke={isEditing ? "transparent" : ""} />;
  }

  /**
   * @inheritdoc
   */
  component(shape: IBarChartShape) {
    const tldrawEditor = this.editor;
    const { props, id } = shape;
    const isEditing = useValue("barChart.isEditing", () => tldrawEditor.getCurrentPageState().editingShapeId === shape.id, [
      tldrawEditor,
      shape.id,
    ]);
    const isInteracting = useIsInteracting(shape.id);
    const parentId = useValue("barChart.parentId", () => tldrawEditor.getShape(shape.id)!.parentId, [shape.id]);
    const datastore = useBoardDatastore();
    const widthRef = React.useRef<number>(shape.props.w);
    const facetsRef = React.useRef<Record<string, any>>({});
    const { t } = useTranslation();

    const { handleInputPointerDown } = useShapeEvents(shape.id);

    React.useEffect(() => {
      if (widthRef.current) {
        this.editor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              w: widthRef.current,
            },
          },
        ]);
      }
    }, [widthRef.current]);

    // Get the parent data from the datastore (without strong types)
    const parentData = useValue(
      "barChart.parentData",
      () =>
        datastore.state.get()[parentId]?.get() as IAnalysisShapeData<
          Array<Record<string, any>>,
          Record<string, any>,
          Record<string, any>,
          Record<string, any>
        >,
      [datastore, parentId],
    );

    // Get the filtered data from the datastore
    const slotsData: Record<string, any> =
      parentData?.slotsData?.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const dataLoading = parentData?.dataLoading?.get() || false;
    const configLoading = parentData?.configLoading?.get() || false;
    const onFieldFacetManagerFieldChange = parentData?.onFieldFacetManagerFieldChange?.get();
    // Get the other required data, such as facets and config from the datastore
    const facets = parentData?.facets.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const config = parentData?.config.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const yAxisTickFormat = parentData?.yAxisTickFormat?.get();

    const selectedFacets = useValue(
      "selectedFacets",
      () => {
        const selectedValues = facets[props.field] || DataUtils.getImmutableEmptyArray<string>();
        return { selectedValues };
      },
      [facets],
    );

    if (!parentData) {
      return (
        <HTMLContainer
          id={shape.id}
          style={{
            background: "#fff",
            display: "block",
            pointerEvents: "all",
            padding: WARNING_PADDING,
          }}
        >
          {t("Components.Charts.NoParent")}
        </HTMLContainer>
      );
    }

    if (dataLoading || configLoading) {
      return (
        <HTMLContainer
          id={shape.id}
          style={{
            background: "#fff",
            display: "block",
            pointerEvents: "all",
            overflow: "hidden",
          }}
        >
          <BarChartLoader />
        </HTMLContainer>
      );
    }

    if (!config.components) {
      return (
        <HTMLContainer
          id={shape.id}
          style={{
            background: "#fff",
            display: "block",
            pointerEvents: "all",
            padding: WARNING_PADDING,
          }}
        >
          {t("Components.Charts.NoConfigAvailable")}
        </HTMLContainer>
      );
    }

    const relatedData = slotsData[props.field] || DataUtils.getImmutableEmptyArray<Record<string, any>>();
    const fieldConfig = config.components[props.field];
    const fieldShapeConfig = fieldConfig.config;
    const fill = fieldShapeConfig.barMarkOptions.fill;
    facetsRef.current = facets;
    const dynamicValues: Record<string, any> = {};
    if (fieldShapeConfig.barHeight) {
      dynamicValues["height"] = this._getDynamicHeight(fieldShapeConfig, relatedData);
    }
    return (
      <HTMLContainer
        id={shape.id}
        style={{
          background: "transparent",
          display: "block",
          pointerEvents: "all",
        }}
      >
        <div
          style={{
            pointerEvents: isEditing || isInteracting ? "auto" : "none",
          }}
          onPointerDown={handleInputPointerDown}
        >
          {!relatedData || relatedData.length === 0 ? (
            <div style={{ width: "100%", height: "100%", background: "#fff", padding: WARNING_PADDING }}>
              {t("Components.Charts.NoData")}
            </div>
          ) : (
            <AutoResizingContainer onResize={this._onShapeResize.bind(this, shape)}>
              <div className="bar-chart-shape-label">
                <label>{fieldConfig.label}</label>
              </div>
              <BarChart
                barMarkOptions={{
                  ...fieldShapeConfig.barMarkOptions,
                  fill: typeof fill === "string" ? fill : (dataItem: any) => fill(facetsRef.current, dataItem),
                }}
                data={relatedData}
                direction={fieldShapeConfig.direction}
                facets={selectedFacets}
                groupReducer={fieldShapeConfig.groupReducer}
                metadata={{
                  ...dynamicValues,
                  ...fieldShapeConfig.metadata,
                  y: {
                    ...fieldShapeConfig.metadata?.y,
                    tickFormat: yAxisTickFormat,
                  },
                }}
                onBarClick={(e, result) => {
                  const value = result.datum[fieldShapeConfig.yField];
                  const currentValues = facetsRef.current[props.field] || [];
                  let newValues;
                  if (currentValues.includes(value)) {
                    newValues = currentValues.filter((v: any) => v !== value);
                  } else {
                    newValues = [...currentValues, value];
                  }
                  onFieldFacetManagerFieldChange && onFieldFacetManagerFieldChange(props.field, [...newValues]);
                }}
                textField={fieldShapeConfig.textField}
                textMarkOptions={fieldShapeConfig.textMarkOptions}
                textReducer={fieldShapeConfig.textReducer}
                xField={fieldShapeConfig.xField}
                yField={fieldShapeConfig.yField}
              />
            </AutoResizingContainer>
          )}
        </div>
      </HTMLContainer>
    );
  }

  // *********************************************
  // Private methods, event handlers
  // *********************************************/
  /**
   * Handles shape resize events.
   *
   * @param shape The shape that was resized.
   * @param width The new width.
   * @param height The new height.
   */
  private _onShapeResize(shape: IBarChartShape, width: number, height: number) {
    const { id, type, props } = shape;
    this.editor.updateShapes([
      {
        id,
        type,
        props: {
          h: height || shape.props.h,
        },
      },
    ]);
  }

  // *********************************************
  // Private methods
  // *********************************************/
  /**
   * Calculates the dynamic height of the bar chart
   *
   * @param fieldShapeConfig Field shape config, which contains markOptions & metadata
   * @param relatedDataLength Data length
   * @returns
   */
  private _getDynamicHeight(fieldShapeConfig: Record<string, any>, relatedData: Array<Record<string, any>> = []) {
    const relatedDataLength = DataUtils.getGroupedKeys<Record<string, any>>(relatedData, fieldShapeConfig.yField).length;
    const insetTop = fieldShapeConfig.barMarkOptions.insetTop || 0;
    const insetBottom = fieldShapeConfig.barMarkOptions.insetBottom || 0;
    const insetHeight = insetTop * relatedDataLength + insetBottom * relatedDataLength + 2 * relatedDataLength;
    return (
      fieldShapeConfig.barHeight * relatedDataLength +
      CHART_DEFAULT_DIMENSIONS.marginTop +
      CHART_DEFAULT_DIMENSIONS.marginBottom +
      insetHeight
    );
  }
}
