import { TFunction } from "i18next";
import { WorkspacesQuery } from "GraphQL/Generated/Apollo";

export function formatCollaborators(
  userId: string,
  acl: WorkspacesQuery["workspaces"][number]["workspaceAccessControlList"],
  t: TFunction,
) {
  let key = "Components.Collaborators.You";
  const collaborators = acl.filter((acl) => acl.user?.id !== userId);
  let count = collaborators.length;

  // We can't use i18n to automatically give these and the interval post processor didn't work...
  switch (collaborators.length) {
    case 0:
      key = "Components.Collaborators.JustYou";
      break;
    case 1:
      key = "Components.Collaborators.OneOther";
      break;
    case 2:
      key = "Components.Collaborators.FewOthers";
      break;
    default:
      key = "Components.Collaborators.ManyOthers";
  }

  return t(key, { count, val: collaborators.map((a) => a.user?.name || a.userGroup?.name) });
}

export function formatWorkspaceUpdatedAt(updatedAt: WorkspacesQuery["workspaces"][number]["updatedAt"], t: TFunction) {
  const daysSince = Math.floor((Date.now() - new Date(updatedAt).getTime()) / (1000 * 60 * 60 * 24));
  switch (daysSince) {
    case 0:
      return t("Components.WorkspaceGridItem.UpdatedAt.UpdatedToday");
    case 1:
      return t("Components.WorkspaceGridItem.UpdatedAt.UpdatedYesterday");
    default:
      return t("Components.WorkspaceGridItem.UpdatedAt.UpdatedDaysAgo", { days: daysSince });
  }
}
