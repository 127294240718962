import { Collaboration as TiptapCollaboration } from "@tiptap/extension-collaboration";
import { EditorView } from "@tiptap/pm/view";
import { ySyncPlugin, yUndoPlugin, yUndoPluginKey } from "y-prosemirror";

export const Collaboration = TiptapCollaboration.extend({
  addProseMirrorPlugins() {
    const fragment = this.options.fragment ? this.options.fragment : this.options.document.getXmlFragment(this.options.field);

    const yUndoPluginInstance = yUndoPlugin();
    const originalUndoPluginView = yUndoPluginInstance.spec.view;

    yUndoPluginInstance.spec.view = (view: EditorView) => {
      const undoManager = yUndoPluginKey.getState(view.state).undoManager;
      if (undoManager.restore) {
        undoManager.restore();
        undoManager.restore = () => {};
      }

      const viewRet = originalUndoPluginView ? originalUndoPluginView(view) : undefined;

      return {
        destroy: () => {
          const hasUndoManSelf = undoManager.trackedOrigins.has(undoManager);
          const observers = undoManager._observers;

          undoManager.restore = () => {
            if (hasUndoManSelf) {
              undoManager.trackedOrigins.add(undoManager);
            }
            undoManager.doc.on("afterTransaction", undoManager.afterTransactionHandler);
            undoManager._observers = observers;
          };

          if (viewRet && viewRet.destroy) {
            viewRet.destroy();
          }
        },
      };
    };

    return [
      ySyncPlugin(fragment, {
        onFirstRender: () => {
          this.options.onFirstRender?.apply(this);
        },
      }),
      yUndoPluginInstance,
    ];
  },
});
