import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

import { createTheme } from "@mui/material/styles";
import { LinkProps } from "@mui/material/Link";
import { red, yellow } from "@mui/material/colors";
import React from "react";

const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
});

declare module "@mui/material/styles" {
  interface Palette {
    danger?: Palette["primary"];
  }

  interface PaletteOptions {
    danger?: PaletteOptions["primary"];
  }
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    danger: true;
  }
}

// MUI's default theme palett
const { palette } = createTheme();

const Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: {
          "&[disabled]": {
            color: palette.action.disabled,
            pointerEvents: "none",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: (theme) => ({
          [theme.theme.breakpoints.up("sm")]: {
            minHeight: "40px",
          },
          [theme.theme.breakpoints.up("xs")]: {
            minHeight: "40px",
          },
        }),
      },
    },
  },
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      main: "#2f80ed",
    },
    secondary: {
      main: "#DD2D4A",
    },
    danger: {
      main: red.A400,
    },
    error: {
      main: red.A400,
    },
    warning: {
      main: yellow.A400,
    },
  },
  typography: {
    fontFamily: "Graphik",
  },
});

export default Theme;
