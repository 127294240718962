import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import UserGroupForm from "./UserGroupForm";

export default function UserGroupNew() {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h4">{t("Pages.UserGroups.New")}</Typography>
      <UserGroupForm />
    </>
  );
}
