import { Permissions } from "@bigpi/permission";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { Box, IconButton, Drawer as MuiDrawer, Divider, Tooltip } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LogoIcon from "Assets/LogoIcon";
import HasPermission from "Components/HasPermission/HasPermission";
import SettingsMenu from "Components/SettingsMenu/SettingsMenu";
import { RoutePaths } from "RoutePaths";

interface RailItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

function RailItemLink(props: RailItemLinkProps) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} role={""} />
      )),
    [to],
  );

  return (
    <Box sx={{ textAlign: "center", mt: 1 }}>
      <Tooltip title={primary} placement="right">
        <IconButton component={renderLink} size="small">
          {icon}
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default function NavigationRail() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      component="nav"
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 1,
        paddingRight: 1,
        paddingTop: 1,
        paddingBottom: 1,
        width: "48px",
        borderRight: `1px solid ${theme.palette.divider}`,
      }}
    >
      <LogoIcon fontSize="large" sx={{ alignSelf: "center" }} />
      <Box sx={{ mt: "36px", textAlign: "center", display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <RailItemLink to={RoutePaths.workspaces.path} primary={t("Components.Sidebar.Workspaces")} icon={<WorkspacesIcon />} />
        <HasPermission permission={Permissions.PatronLibraryRead}>
          <RailItemLink to={RoutePaths.library.path} primary={t("Components.Sidebar.Library")} icon={<FolderIcon />} />
        </HasPermission>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Divider />
        <HasPermission permission={Permissions.UserGroupAdmin}>
          <RailItemLink
            to={RoutePaths.userGroups.path}
            primary={t("Components.Sidebar.Admin")}
            icon={<AdminPanelSettingsOutlinedIcon />}
          />
        </HasPermission>
        <SettingsMenu />
      </Box>
    </Box>
  );
}
