import { StandardHtmlColors } from "@bigpi/cookbook";
import { Box } from "@mui/material";
import * as Plot from "@observablehq/plot";

import * as d3 from "d3";
import React, { useRef, useEffect } from "react";

import { useInspector } from "Notebooks/Hooks";
import { ChartUtils } from "Utils/ChartUtils";
import { DataUtils } from "Utils/DataUtils";

import { getHistogramChartPlot } from "../Elements/HistogramChartPlot";
//css
import "./GroupHistogramChart.css";

// *********************************************
// Props interface
// *********************************************/
interface GroupHistogramChartProps {
  canInteract: boolean;
  data: Array<Record<string, any>>;
  dataFormatters?: Record<string, any>;
  facetFields: Record<string, any>;
  facets: Record<string, any>;
  fillReducer?: (colorScale: d3.ScaleSequential<string, never>) => () => string;
  groupReducers?: Record<string, any>;
  marginLeft: number;
  onUpdateFacets: (latestFacets: Record<string, any>) => void;
  plotWidth: number;
  scales: Record<string, any>;
  startColor?: string;
  xDomainValues: Array<Date>;
  xField: string;
  yAxisTickFormat?: (value: string) => string;
  yGroupField: string;
  yItemField?: string;
}

// *********************************************
// Common data
// *********************************************/
// This is to make rects on the labels chart doesn't appear
const xAxisLabelsHeight = 60.5;
const groupPlotHeight = 500;
const singleItemHeight = 50;
const marginTop = 0;
const marginRight = 10;
const marginBottom = 50;
const insetLeft = 10;
const insetRight = 40;
const padding = 0.05;

// *********************************************
// Component
// *********************************************/
export const GroupHistogramChart: React.FC<GroupHistogramChartProps> = (props) => {
  // Refs
  const chartRef = useRef<HTMLDivElement>(null);

  // Inspector to replace with latest element
  const module = useInspector("props", props, chartRef, generatePlotElements);
  useEffect(() => {
    if (module) {
      module.redefine("props", props);
    }
  }, [module, props]);
  return (
    <Box>
      <div ref={chartRef} className="group-histogram-chart"></div>
    </Box>
  );

  // *********************************************
  // CALLBACKS/UTILS
  // *********************************************/
  /**
   * Generates plot elements for the group histogram chart
   *
   * @param props Latest props
   * @returns
   */
  function generatePlotElements(props: GroupHistogramChartProps): HTMLElement {
    const { data, yGroupField, yItemField, facets, facetFields, scales, startColor = "interpolateBlues" } = props;
    const colorInterpolator = d3[startColor as keyof typeof d3] as (t: number) => string;
    const groupColorScale = d3.scaleSequential(colorInterpolator).domain(scales.histogramChartScale.groupScale);
    const itemsColorScale = d3.scaleSequential(colorInterpolator).domain(scales.histogramChartScale.itemsScale);
    // Sort by group field
    const charts: Array<HTMLElement> = [];
    const groups = DataUtils.getGroupedItems(data, yGroupField);
    // X-Axis labels
    const xAxisLabel = generateXAxisLabels(props);
    charts.push(xAxisLabel);

    for (let [groupKey, groupValue] of groups) {
      // Group level
      const groupPlot = generateHistogramPlot(props, groupValue, yGroupField, groupColorScale, 100);

      // Item level
      if (yItemField) {
        const itemGroupKeys = DataUtils.getGroupedKeys(groupValue, yItemField);
        const itemPlot = generateHistogramPlot(
          props,
          groupValue,
          yItemField,
          itemsColorScale,
          singleItemHeight * itemGroupKeys.length + singleItemHeight,
        );

        // Detail-summary tags
        const open = (facets[facetFields.expandedGroupsFacetField] || []).includes(groupKey);
        const details = document.createElement("div");
        details.classList.add("details");
        if (open) {
          details.setAttribute("open", "");
        }

        const summary = document.createElement("div");
        summary.classList.add("summary");
        summary.addEventListener("pointerup", (e) => onGroupClick(e, groupKey, props));
        summary.append(groupPlot);

        if (open) {
          const div = document.createElement("div");
          div.append(itemPlot);

          details.append(summary, div);
        } else {
          details.append(summary);
        }
        charts.push(details);
      } else {
        charts.push(groupPlot);
      }
    }

    const div = document.createElement("div");
    charts.forEach((element) => {
      if (element) {
        div.append(element);
      }
    });

    return div;
  }

  /**
   * Generates x-axis labels
   *
   * @param props Component props
   * @returns
   */
  function generateXAxisLabels(props: GroupHistogramChartProps): HTMLElement {
    const { canInteract, plotWidth, marginLeft, xDomainValues, facets } = props;
    const xAxisScale = d3.select(
      Plot.plot({
        style: {
          backgroundColor: "transparent",
        },
        width: plotWidth,
        height: xAxisLabelsHeight,
        marginLeft,
        marginRight,
        marginTop: 60,
        insetLeft,
        insetRight,
        padding,
        x: {
          axis: "top",
          label: "",
          line: false,
          tickSize: 0,
          tickPadding: 30,
        },
        marks: [
          Plot.rect(
            xDomainValues,
            Plot.binX(
              {},
              {
                // @ts-ignore TODO: issue: interval uncompatability
                thresholds: d3[facets.selectedTimeScale || "utcDay"],
              },
            ),
          ),
        ],
      }),
    );

    // Adds brush selection to the x-axis
    if (xAxisScale) {
      const sortedData = xDomainValues.sort((a, b) => d3.descending(b, a));
      const xScale = d3.scaleUtc([sortedData[0], sortedData[sortedData.length - 1]], [marginLeft, plotWidth - marginRight]);
      // Brush
      const brush = d3
        .brushX()
        .extent([
          [marginLeft || 0, 0],
          [plotWidth - marginRight, xAxisLabelsHeight],
        ])
        .on("end", (params) => {
          if (params.type === "end" && params.selection) {
            const startDate = xScale.invert(params.selection[0]);
            const endDate = xScale.invert(params.selection[1]);
            onXAxisSelection([startDate.toISOString(), endDate.toISOString()], props);
          }
        })
        .touchable(true);

      xAxisScale
        .append("g")
        .call(brush)
        .select(".overlay")
        .attr("pointer-events", canInteract ? "all" : "none");
    }
    return xAxisScale.node() as HTMLElement;
  }

  /**
   * Generates Group/Item level histogram plot
   *
   * @param props Component props
   * @param data Data to plot
   * @param yField Y-axis field
   * @param height Plot height
   * @returns
   */
  function generateHistogramPlot(
    props: GroupHistogramChartProps,
    data: Array<Record<string, any>>,
    yField: string,
    colorScale: d3.ScaleSequential<string, never>,
    height?: number,
  ): HTMLElement {
    const { facets, facetFields, xField, yItemField, marginLeft, plotWidth, yAxisTickFormat, fillReducer, groupReducers } = props;
    const chart = getHistogramChartPlot(
      "",
      data,
      {
        grid: false,
        height,
        insetLeft,
        insetRight,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        padding,
        width: plotWidth,
        x: {
          axis: null,
        },
        fy: {
          tickFormat: yAxisTickFormat,
          label: "",
        },
      },
      xField,
      yField,
      groupReducers?.histogramGroupReducer,
      fillReducer ? fillReducer(colorScale) : () => "",
      {
        fy: yField,
        interval: facets.selectedTimeScale || "utcDay",
        insetTop: 1,
        insetBottom: 1,
        insetLeft: 0.5,
        insetRight: 0.5,
        // tip: true,
      },
      undefined,
      onRectClick.bind(undefined, props),
      onLabelClick.bind(undefined, props),
    );

    // Highlights the selected y-axis label
    if (yField === yItemField) {
      const axisSelection = facets[facetFields.axisSelectionFacetField];
      const selectedYItems = axisSelection && axisSelection.map((d: Record<string, any>) => d.selectedYItem);
      d3.select(chart)
        .select(`[aria-label*="y-axis"]`)
        .selectAll("text")
        .each(function (d) {
          if (d3.select(this) && d3.select(this).text() && selectedYItems && selectedYItems.includes(d3.select(this).text())) {
            d3.select(this).attr("fill", StandardHtmlColors.blue80);
          }
        });
    }

    return chart;
  }

  // *********************************************
  // CALLBACKS/UTILS
  // *********************************************/
  /**
   * Handles click on the pseudo element of summary div
   *
   * @param e Pointer event object
   * @param groupKey Group key to be expanded/collapsed
   * @param props Component props
   * @returns
   */
  function onGroupClick(e: PointerEvent, groupKey: string, props: GroupHistogramChartProps) {
    // Ignores clicks on plot svg element
    if (!(e.target instanceof HTMLDivElement)) {
      return;
    }
    const { onUpdateFacets, facets, facetFields } = props;
    const expandedGroups = [...(facets[facetFields.expandedGroupsFacetField] || [])];
    if (expandedGroups.includes(groupKey)) {
      expandedGroups.splice(expandedGroups.indexOf(groupKey), 1);
    } else {
      expandedGroups.push(groupKey);
    }

    onUpdateFacets({
      ...facets,
      [facetFields.expandedGroupsFacetField]: expandedGroups,
    });
    e.stopPropagation();
    e.preventDefault();
  }

  /**
   * On brush selection of x-axis
   *
   * @param selection Brush selection array
   * @param props Component props
   */
  function onXAxisSelection(selection: Array<string>, props: GroupHistogramChartProps) {
    const { onUpdateFacets, facets, facetFields } = props;
    onUpdateFacets({
      ...facets,
      [facetFields.axisSelectionFacetField]: [
        {
          selectedXAxis: selection,
        },
      ],
    });
  }

  /**
   * On rect click
   *
   * @param props Component props
   * @param e Event object
   * @param selectedValue Selected value, which includes x, x2, fy, datum
   * @param yField Y-axis field to represent group/item
   * @returns
   */
  function onRectClick(props: GroupHistogramChartProps, e: PointerEvent, selectedValue: Record<string, any>, yField: string) {
    const { x1, x2, fy } = selectedValue;
    const { data, xField, yGroupField, yItemField, onUpdateFacets, facets, facetFields } = props;

    const xValue = [x1.toISOString(), x2.toISOString()];

    const dataItem = data.find((item) => {
      const inRange = ChartUtils.isInRange(xValue.join("#"), item[xField]);
      if (item[yField] === fy && inRange) {
        return true;
      }
    });
    if (!x1 || !x2 || !fy || !dataItem) {
      return;
    }

    const yGroupValue = yGroupField === yField ? fy : dataItem[yGroupField];
    const yItemValue = yItemField === yField ? fy : undefined;

    let facetValues = facets[facetFields.axisSelectionFacetField] || [];
    facetValues = JSON.parse(JSON.stringify(facetValues));
    if (facetValues.length === 0) {
      facetValues.push({
        selectedXAxis: xValue,
        selectedYGroup: yGroupValue,
        selectedYItem: yItemValue,
      });
    } else {
      if (
        // This means there is already a selection only on ygroup/yitem
        !facetValues[0].selectedXAxis ||
        // This means there is already a selection only on x-axis
        (facetValues[0].selectedXAxis && !facetValues[0].selectedYGroup && !facetValues[0].selectedYItem) ||
        // This means there is new change on item selection
        (facetValues[0].selectedYItem && !yItemValue) ||
        (!facetValues[0].selectedYItem && yItemValue)
      ) {
        facetValues = [];
      }
      const index = facetValues.findIndex((item: Record<string, any>) => {
        return (
          item.selectedXAxis.join("#") === xValue.join("#") &&
          item.selectedYGroup === yGroupValue &&
          item.selectedYItem === yItemValue
        );
      });

      if (index > -1) {
        facetValues.splice(index, 1);
      } else {
        facetValues.push({
          selectedXAxis: xValue,
          selectedYGroup: yGroupValue,
          selectedYItem: yItemValue,
        });
      }
    }

    onUpdateFacets({
      ...facets,
      [facetFields.axisSelectionFacetField]: facetValues,
    });
    e.stopPropagation();
    e.preventDefault();
  }

  /**
   * Handles label click
   *
   * @param props Component props
   * @param e Event object
   * @param selectedValue Selected label value
   * @param yField Y-axis field to represent group/item
   */
  function onLabelClick(props: GroupHistogramChartProps, e: PointerEvent, selectedValue: string, yField: string) {
    const { xField, yGroupField, yItemField, facets, facetFields, onUpdateFacets } = props;
    let facetValues = JSON.parse(JSON.stringify(facets[facetFields.axisSelectionFacetField] || []));

    const xValue = undefined;
    const yGroupValue = yGroupField === yField ? selectedValue : undefined;
    const yItemValue = yItemField === yField ? selectedValue : undefined;

    if (facetValues.length === 0) {
      facetValues.push({
        selectedXAxis: xValue,
        selectedYGroup: yGroupValue,
        selectedYItem: yItemValue,
      });
    } else {
      if (facetValues[0].selectedXAxis || !facetValues[0][yField === yGroupField ? "selectedYGroup" : "selectedYItem"]) {
        facetValues = [];
      }
      const index = facetValues.findIndex((item: Record<string, any>) => {
        return yField === yGroupField ? item.selectedYGroup === yGroupValue : item.selectedYItem === yItemValue;
      });

      if (index > -1) {
        facetValues.splice(index, 1);
      } else {
        facetValues.push({
          selectedXAxis: xValue,
          selectedYGroup: yGroupValue,
          selectedYItem: yItemValue,
        });
      }
    }

    onUpdateFacets({
      ...facets,
      [facetFields.axisSelectionFacetField]: facetValues,
    });

    e.stopPropagation();
    e.preventDefault();
  }
};
