import { IBoardSearchShapeMatch, getRegexForSearchTerm } from "@bigpi/cookbook";
import ReactDomServer from "react-dom/server";

/**
 * Get plain text from HTML.
 *
 * @param html The HTML to extract text from.
 *
 * @returns The text extracted from the HTML.
 */
export function getPlainTextFromHtml(html: string) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

/**
 * Get plain text from a JSX element.
 *
 * @param element The JSX element to extract text from.
 *
 * @returns The text extracted from the JSX element.
 */
export function getPlainTextFromJsxElement(element: JSX.Element) {
  return getPlainTextFromHtml(ReactDomServer.renderToStaticMarkup(element));
}

/**
 * Get matches for the search term in the text content.
 *
 * @param textContent The text content to search for matches.
 * @param searchTerm The search term to match.
 *
 * @returns An array of matches for the search term in the text.
 */
export function getBoardSearchMatchesFromTextContent(textContent: string, searchTerm: string): Array<IBoardSearchShapeMatch> {
  const searchMatches: Array<IBoardSearchShapeMatch> = [];
  const searchRegex = getRegexForSearchTerm(searchTerm, {
    caseInsensitive: true,
    disableRegexSearch: true,
  });

  // Find all matches for the search term in the text content.
  const matches = Array.from(textContent.matchAll(searchRegex)).filter(([matchText]) => matchText.trim());
  matches.forEach((_match, index) => {
    searchMatches.push({ match: searchTerm, index });
  });

  return searchMatches;
}
