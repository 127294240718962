import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import { Box } from "@mui/material";

import { useCreateDocumentMutation, useDocumentLazyQuery } from "GraphQL/Generated/Apollo";
import { useImageTokenPolling } from "Editor/Extensions/PasteContent/useImageTokenPolling";
import { Document } from "Components/Document/Document";
import { linkToId } from "RoutePaths";
import { CommandContext } from "CommandContext";

export default function DocumentPage() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [createDocument] = useCreateDocumentMutation();
  const [getDocument] = useDocumentLazyQuery();

  useImageTokenPolling();

  const id = params.id || "";
  const defaultName = t("Pages.Document.NewDocumentName", {
    val: new Date(),
    formatParams: {
      val: { dateStyle: "short", timeStyle: "short" },
    },
  });

  const [documentName, setDocumentName] = useState(defaultName);

  // Set current application session context
  CommandContext.replaceSessionContext([{ documentId: id }]);

  useEffect(() => {
    if (params.id === "new") {
      // TODO: This needs to refresh client cache
      createDocument({
        variables: {
          input: {
            name: defaultName,
            content: {
              contentType: "text/html",
              content: "",
            },
          },
        },
        refetchQueries: ["Documents"],
      }).then((result) => {
        if (result.data?.createDocument?.id && result.data?.createDocument?.id !== "new") {
          setDocumentName(result.data.createDocument.name);
          navigate(linkToId("document", result.data.createDocument.id), { replace: true });
        } else {
          console.error("TODO: Error creating new document");
        }
      });
    } else if (params.id) {
      getDocument({
        variables: {
          id,
        },
      }).then((response) => {
        if (response.data?.document?.name) {
          setDocumentName(response.data.document.name);
        } else {
          console.error("TODO: Error getting document details");
        }
      });
    }
  }, [params.id]);

  return (
    <>
      <Helmet>
        <title>{t("Pages.Document.Title")}</title>
      </Helmet>
      <Box sx={{ width: "100%" }}>{params.id !== "new" && params.id && <Document id={params.id} name={documentName} />}</Box>
    </>
  );
}
