import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { useUpdateWorkspaceMutation, useWorkspaceBoardQuery } from "GraphQL/Generated/Apollo";

export interface IMakeDefaultWorkspaceBoardDialogProps {
  open: boolean;
  workspaceId: string;
  workspaceBoardId: string;
  onClose: () => void;
}

export default function MakeDefaultWorkspaceBoardDialog(props: IMakeDefaultWorkspaceBoardDialogProps) {
  const { open, workspaceId, workspaceBoardId } = props;
  const { t } = useTranslation();
  const [updateWorkspace] = useUpdateWorkspaceMutation();
  const { data, loading, error } = useWorkspaceBoardQuery({ variables: { id: workspaceBoardId } });
  const [errorMessage, setErrorMessage] = useState("");

  function onClose() {
    setErrorMessage("");
    props.onClose();
  }

  function onMakeDefaultWorkspaceBoard(): void {
    updateWorkspace({
      variables: {
        input: {
          id: workspaceId,
          defaultWorkspaceBoardId: workspaceBoardId,
        },
      },
      refetchQueries: ["WorkspaceBoards", "Workspace"],
    })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(t("Components.MakeDefaultWorkspaceBoardDialog.Error"));
      });
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
        <DialogTitle variant="h5">
          {t("Components.MakeDefaultWorkspaceBoardDialog.Title", { name: data?.workspaceBoard?.name || "" })}
        </DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <DialogContentText>{t("Components.MakeDefaultWorkspaceBoardDialog.Tip")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onMakeDefaultWorkspaceBoard}>{t("Global.Action.MakeDefaultBoard")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
