import { useAuthUser } from "@frontegg/react";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { Box, ButtonBase, Card, CardContent, CardMedia, Grid, Typography, alpha } from "@mui/material";
import { useTranslation } from "react-i18next";

import { WorkspacesQuery } from "GraphQL/Generated/Apollo";
import Theme from "Theme";
import { getImageSrcFromImageBase64 } from "Utils/DomToImageUtils";
import { formatCollaborators } from "Utils/WorkspaceUtils";

export interface IWorkspaceTemplateGridItemProps {
  selectedTemplateId: string | null;
  onSelectTemplate: (templateId: string | null) => void;
  workspace: WorkspacesQuery["workspaces"][number];
}

export function WorkspaceTemplateGridItem(props: IWorkspaceTemplateGridItemProps) {
  const { selectedTemplateId, onSelectTemplate, workspace } = props;

  const user = useAuthUser();
  const { t } = useTranslation();

  return (
    <Grid item xs={1} sm={2} md={3} key={workspace.id}>
      <ButtonBase
        sx={{ height: "100%", width: "100%" }}
        onClick={() => {
          if (selectedTemplateId === workspace.id) {
            onSelectTemplate(null);
          } else {
            onSelectTemplate(workspace.id);
          }
        }}
        disableRipple
      >
        <Card
          variant="outlined"
          sx={{
            height: "100%",
            width: "100%",
            borderColor: selectedTemplateId === workspace.id ? "primary.main" : "default.main",
            borderWidth: "2px",
            backgroundColor: selectedTemplateId === workspace.id ? alpha(Theme.palette.primary.main, 0.1) : "#fff",
          }}
        >
          <CardMedia sx={{ width: "100%", minHeight: "100px", display: "flex", flexWrap: "wrap", alignContent: "center" }}>
            {workspace.defaultWorkspaceBoard?.thumbnail ? (
              <img width="100%" src={getImageSrcFromImageBase64(workspace.defaultWorkspaceBoard.thumbnail)} />
            ) : (
              <Box sx={{ width: "100%" }}>
                <WorkspacesIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              </Box>
            )}
          </CardMedia>
          <CardContent>
            <Typography gutterBottom variant="body1" component="span">
              {workspace.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {formatCollaborators(user.id, workspace.workspaceAccessControlList, t)}
            </Typography>
          </CardContent>
        </Card>
      </ButtonBase>
    </Grid>
  );
}
