import { Permissions } from "@bigpi/permission";
import { useAuthUser } from "@frontegg/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/react";
import React, { useEffect } from "react";
import { Routes, Navigate, Route, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";

import { CommandContext } from "CommandContext";
import HasPermission from "Components/HasPermission/HasPermission";
import { RoutePaths } from "RoutePaths";
import Config from "./Config";
import DocumentPage from "./Pages/Document/DocumentPage";
import ErrorLayout from "./Pages/ErrorLayout";
import LibraryPage from "./Pages/Library/LibraryPage";
import MainLayout from "./Pages/MainLayout";
import NotFoundPage from "./Pages/Errors/NotFound/NotFoundPage";
import UserGroupPage from "./Pages/UserGroups/UserGroupPage";
import UserGroupsPage from "./Pages/UserGroups/UserGroupsPage";
import WorkspaceBoardPage from "./Pages/WorkspaceBoard/WorkspaceBoardPage";
import WorkspacePage from "./Pages/Workspace/WorkspacePage";
import WorkspacesPage from "./Pages/Workspaces/WorkspacesPage";

Sentry.init({
  dsn: Config.sentryDsn,
  environment: Config.sentryEnvironment,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
      tracingOrigins: [Config.sentryTracingOrigins],
    }),
  ],
  release: Config.sentryRelease,
  tracesSampleRate: Config.sentryTracesSampleRate,
});

function RequireAuth({ children }: { children: JSX.Element }) {
  const user = useAuthUser();
  const location = useLocation();

  const commandContext = CommandContext.getCommandContext();

  useEffect(() => {
    CommandContext.patchCommandContext({
      organization: {
        ...commandContext.organization,
        organizationId: user?.tenantId,
      },
      user: {
        userId: user?.id,
      },
    });
  }, [user?.tenantId, user?.id]);

  if (user === null) {
    /*
     * Redirect user to the /login page, but save the current location they were
     * trying to go to when they were redirected. This allows us to send them
     * along to that page after they login, which is a nicer user experience
     * than dropping them off on the home page.
     */
    return <Navigate to="/account/login" state={{ from: location }} replace />;
  } else if (user) {
    Sentry.setUser({ id: user.id, email: user.email });
  }

  return children;
}

export default function AppRoutes() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  // Clear initial session context. It will be set by the page accordingly when required.
  useEffect(() => {
    CommandContext.replaceSessionContext([]);
  }, []);

  return (
    <SentryRoutes>
      <Route path={RoutePaths.home.path} element={<MainLayout />}>
        <Route
          path={RoutePaths.document.path}
          element={
            <RequireAuth>
              <DocumentPage />
            </RequireAuth>
          }
        />

        <Route
          path={RoutePaths.library.path}
          element={
            <RequireAuth>
              <HasPermission permission={Permissions.PatronLibraryRead}>
                <LibraryPage />
              </HasPermission>
            </RequireAuth>
          }
        />

        <Route
          path={RoutePaths.userGroups.path}
          element={
            <RequireAuth>
              <HasPermission permission={Permissions.UserGroupAdmin}>
                <UserGroupsPage />
              </HasPermission>
            </RequireAuth>
          }
        />
        <Route
          path={RoutePaths.userGroup.path}
          element={
            <RequireAuth>
              <HasPermission permission={Permissions.UserGroupAdmin}>
                <UserGroupPage />
              </HasPermission>
            </RequireAuth>
          }
        />

        <Route
          index
          element={
            <RequireAuth>
              <WorkspacesPage />
            </RequireAuth>
          }
        />
        <Route
          path={RoutePaths.workspaces.path}
          element={
            <RequireAuth>
              <WorkspacesPage />
            </RequireAuth>
          }
        />
        <Route
          path={RoutePaths.workspace.path}
          element={
            <RequireAuth>
              <WorkspacePage />
            </RequireAuth>
          }
        />
        <Route
          path={RoutePaths.workspaceBoard.path}
          element={
            <RequireAuth>
              <WorkspaceBoardPage />
            </RequireAuth>
          }
        />
      </Route>

      <Route path="*" element={<ErrorLayout />}>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </SentryRoutes>
  );
}
