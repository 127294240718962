import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useTranslation } from "react-i18next";

export default function LogoIcon(props: SvgIconProps) {
  const { t } = useTranslation();
  return (
    <SvgIcon titleAccess={t("Global.General.ApplicationName")} {...props} viewBox="0 0 1024 1024">
      <path
        id="Middle"
        fill="#5fcada"
        stroke="none"
        d="M 859.984619 268.60614 L 859.984619 274.289368 C 859.984619 381.093262 808.777893 459.43573 694.842407 492.244629 C 722.233948 497.83728 747.044739 505.490479 769.116516 515.430542 L 122.313164 928.494629 L 122.313164 443.812622 L 740.864868 48.749146 C 819.711792 91.996277 859.984619 165.855286 859.984619 268.60614"
      />
      <path
        id="Top"
        fill="#91dae1"
        stroke="none"
        d="M 740.864868 48.749146 L 122.313164 443.812622 L 122.313164 3.622742 L 522.479065 3.622742 C 613.346741 3.622742 686.353271 18.838318 740.864868 48.749146"
      />
      <path
        id="Bottom"
        fill="#45b6ce"
        stroke="none"
        d="M 898.468384 731.483643 L 898.468384 737.234863 C 898.468384 923.807617 775.976196 1022.052856 540.973999 1022.052856 L 122.313164 1022.052856 L 122.313164 928.494629 L 769.116516 515.430542 C 853.691101 553.130005 898.468384 623.072144 898.468384 731.483643"
      />
    </SvgIcon>
  );
}
