import { useQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import {
  DataGridPremium,
  GridApi,
  GridColDef,
  GridRowParams,
  MuiEvent,
  MuiBaseEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridCallbackDetails,
  GridPaginationModel,
  GridRenderEditCellParams,
  GridRowId,
  GridRenderCellParams,
  GridSortModel,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useState } from "react";

import UserDropdown from "Components/UserListDropdown/UserListDropdown";
import { USER_GROUP_MEMBERS_QUERY } from "GraphQL/UserGroupMember";
import {
  useCreateUserGroupMemberMutation,
  useDeleteUserGroupMemberMutation,
  useUpdateUserGroupMemberMutation,
} from "GraphQL/Generated/Apollo";

const DEFAULT_PAGE_SIZE = 10;

interface EditToolbarProps {
  apiRef: React.MutableRefObject<GridApi>;
}

function EditToolbar(props: EditToolbarProps) {
  const { apiRef } = props;

  const handleClick = () => {
    const id = Math.random();
    apiRef.current.updateRows([{ id, isNew: true }]);
    apiRef.current.startRowEditMode({ id });

    // Wait for the grid to render with the new row
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1,
      });
      apiRef.current.setCellFocus(id, "userId");
    });
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

function UserDropdownWrapper(props: {
  id: GridRowId;
  field: string;
  value: any;
  onChange: (id: GridRowId, field: string, value: string | null) => void;
}) {
  const { id, value, field, onChange } = props;

  const handleUserChange = (userId: string | null) => {
    onChange(id, field, userId);
  };

  return <UserDropdown onChange={handleUserChange} value={value} />;
}

export interface UserGroupMemberListProps {
  userGroupId: string;
}

export default function UserGroupMemberList(props: UserGroupMemberListProps) {
  const { userGroupId } = props;
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<Record<string, string>>({});
  const apiRef = useGridApiRef();
  const queryVariables = {
    userGroupId,
    limit: DEFAULT_PAGE_SIZE,
    offset,
    orderBy,
  };
  const { data, loading, fetchMore, error } = useQuery(USER_GROUP_MEMBERS_QUERY, {
    variables: queryVariables,
  });
  const [createUserGroupMemberMutation, { data: createData, loading: createLoading, error: createError }] =
    useCreateUserGroupMemberMutation();
  const [deleteUserGroupMemberMutation, { data: deleteData, loading: deleteLoading, error: deleteError }] =
    useDeleteUserGroupMemberMutation();
  const [updateUserGroupMemberMutation, { data: updateData, loading: updateLoading, error: updateError }] =
    useUpdateUserGroupMemberMutation();

  const rows = data?.userGroupMembers || [{ id: Math.random() }];
  const count = data?.userGroupMemberAggregate.count || 0;

  const sourceColumns: GridColDef[] = [
    {
      field: "userId",
      headerName: "User",
      minWidth: 300,
      editable: true,
      valueGetter: (params: GridValueGetterParams) => params.row.user?.id,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <UserDropdownWrapper
          id={params.id}
          field={params.field}
          value={params.value}
          onChange={(id, field, value) => handleUserChange(id, field, value)}
        />
      ),
      renderCell: (params: GridRenderCellParams) => params.row.user?.name ?? "",
    },
    {
      field: "createdAt",
      headerName: "Created",
      minWidth: 200,
      editable: false,
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      minWidth: 200,
      editable: false,
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => getActions({ id }),
    },
  ];

  const handleUserChange = (id: GridRowId, field: string, value: string | null) => {
    apiRef.current.setEditCellValue({ id, field, value });
  };

  const getActions = ({ id }: { id: GridRowId }) => {
    const isInEditMode = apiRef.current.getRowMode(id) === "edit";

    if (isInEditMode) {
      return [
        <GridActionsCellItem
          icon={<SaveIcon />}
          label="Save"
          onClick={handleSaveClick(id)}
          color="primary"
          nonce=""
          onResize={undefined}
          onResizeCapture={undefined}
        />,
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(id)}
          color="inherit"
          showInMenu={true}
          nonce=""
          onResize={undefined}
          onResizeCapture={undefined}
        />,
      ];
    }

    return [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={handleEditClick(id)}
        color="inherit"
        nonce=""
        onResize={undefined}
        onResizeCapture={undefined}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        onClick={handleDeleteClick(id)}
        color="inherit"
        nonce=""
        onResize={undefined}
        onResizeCapture={undefined}
      />,
    ];
  };

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => (event: React.MouseEvent) => {
    event.stopPropagation();
    apiRef.current.startRowEditMode({ id });
  };

  const handleSaveClick = (id: GridRowId) => async (event: React.MouseEvent) => {
    event.stopPropagation();
    await apiRef.current.stopRowEditMode({ id });

    const row = apiRef.current.getRow(id);

    if (row.isNew) {
      createUserGroupMemberMutation({
        variables: {
          input: {
            userGroupId,
            userId: row.userId as string,
          },
        },
        refetchQueries: [USER_GROUP_MEMBERS_QUERY],
      });
    } else {
      updateUserGroupMemberMutation({
        variables: {
          input: {
            id: row.id,
            userGroupId,
            userId: row.userId as string,
          },
        },
        refetchQueries: [USER_GROUP_MEMBERS_QUERY],
      });
    }
  };

  const handleDeleteClick = (id: GridRowId) => (event: React.MouseEvent) => {
    event.stopPropagation();
    apiRef.current.updateRows([{ id, _action: "delete" }]);

    deleteUserGroupMemberMutation({
      variables: {
        input: {
          id: id as string,
        },
      },
      refetchQueries: [USER_GROUP_MEMBERS_QUERY],
    });
  };

  const handleCancelClick = (id: GridRowId) => async (event: React.MouseEvent) => {
    event.stopPropagation();

    await apiRef.current.stopRowEditMode({ id, ignoreModifications: true });

    const row = apiRef.current.getRow(id);
    if (row.isNew) {
      apiRef.current.updateRows([{ id, _action: "delete" }]);
    }
  };

  return (
    <DataGridPremium
      apiRef={apiRef}
      editMode="row"
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      slots={{
        toolbar: EditToolbar,
      }}
      slotProps={{
        toolbar: { apiRef },
      }}
      autoHeight
      loading={loading || createLoading || deleteLoading || updateLoading}
      rows={rows}
      rowCount={count}
      columns={sourceColumns}
      paginationModel={{ page, pageSize: DEFAULT_PAGE_SIZE }}
      paginationMode="server"
      pagination={true}
      pageSizeOptions={[DEFAULT_PAGE_SIZE]}
      onPaginationModelChange={({ page }: GridPaginationModel) => {
        // TODO: Set limit on last page to avoid unnecessary network call if we don't have a full page
        setOffset(DEFAULT_PAGE_SIZE * page);
        setPage(page);
      }}
      sortingMode="server"
      onSortModelChange={(model: GridSortModel, details: GridCallbackDetails) => {
        const newOrderBy: Record<string, string> = {};

        if (model.length > 0 && model[0].sort) {
          newOrderBy[model[0].field] = model[0].sort;
        }

        setOrderBy(newOrderBy);
      }}
    />
  );
}
