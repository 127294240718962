import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useValue } from "@tldraw/tldraw";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTimeAgo from "react-time-ago";

import ChatMessageToolbar, { ToolbarActionsEnum } from "./ChatMessageToolbar";
import UserAvatar from "Components/UserAvatar/UserAvatar";
import { ChatMessageChannelType, ChatMessagesQuery, useDeleteChatMessageMutation } from "GraphQL/Generated/Apollo";
import { useChatActionExecutor } from "Chat/ChatActionManagers/useChatActionExecutor";
import { parseChatMessageAction } from "./ChatMessageComponents/ChatMessageComponentFactory";

export interface ChatMessageProps {
  channelId: string;
  channelType: ChatMessageChannelType;
  message: ChatMessagesQuery["chatMessages"][0];
  onMessageParentSet: (parentId: string | null, isPrivate?: boolean) => void;
  toolbarActions: Array<ToolbarActionsEnum>;
}

export default function ChatMessage(props: ChatMessageProps) {
  const { channelId, channelType, message, onMessageParentSet, toolbarActions } = props;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { t } = useTranslation();
  const [toolbarAnchorEl, setToolbarAnchorEl] = React.useState<HTMLElement | null>(null);
  const chatActionExecutor = useChatActionExecutor();

  const [deleteChatMessage] = useDeleteChatMessageMutation();
  const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setToolbarAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setToolbarAnchorEl(null);
  }, []);

  const isToolbarOpen = Boolean(toolbarAnchorEl);

  const onReplyClick = useCallback(() => {
    const isPrivate = Array.isArray(message.privateRecipientIds) && message.privateRecipientIds.length > 0;
    // Switch to the thread
    onMessageParentSet(message.id, isPrivate);
  }, [onMessageParentSet, message.id]);

  useEffect(() => {
    setIsDeleteDialogOpen(false);
  }, [message.id]);

  const onDeleteClick = useCallback(() => {
    setIsDeleteDialogOpen(true);
  }, [message.id]);

  const onDeleteConfirm = useCallback(() => {
    setIsDeleteDialogOpen(false);
    deleteChatMessage({
      variables: {
        input: {
          id: message.id,
          channelType,
        },
      },
    });
  }, [message.id, deleteChatMessage, channelType]);

  const onDeleteCancel = useCallback(() => {
    setIsDeleteDialogOpen(false);
    handlePopoverClose();
  }, [message.id, handlePopoverClose]);

  const isDeleted = useValue(
    "isDeleted",
    () => {
      return message.deletedAt && message.deletedBy;
    },
    [message],
  );

  const parseMessage = useCallback(
    (message: string) => {
      return parseChatMessageAction(chatActionExecutor, message, channelType, channelId);
    },
    [chatActionExecutor],
  );

  return (
    <ListItem alignItems="flex-start" dense={true} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      <ListItemAvatar sx={{ minWidth: 38, mr: 1, mt: 0 }}>
        {isDeleted ? (
          <DeleteIcon
            color="disabled"
            sx={{ width: 36, height: 36, backgroundColor: "var(--astra-color-gray20)", padding: 1, borderRadius: "4px" }}
          />
        ) : (
          <UserAvatar user={message.createdByUser} variant="rounded" sx={{ width: 36, height: 36 }} />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          isDeleted ? (
            ""
          ) : (
            <>
              <span style={{ fontWeight: 500 }}>{message.createdByUser?.name} </span>
              <Typography sx={{ display: "inline" }} component="span" variant="caption" color="text.primary">
                <ReactTimeAgo date={new Date(message.createdAt)} timeStyle="round-minute" />
              </Typography>
            </>
          )
        }
        secondary={
          <>
            {isDeleted ? (
              t("Components.Chat.Message.DeletedInfo")
            ) : (
              <Typography sx={{ display: "inline" }} component="div" variant="body1" color="text.primary">
                <div className="chat-message">{parseMessage(message.message)}</div>
              </Typography>
            )}
            {message.childCount > 0 && (
              <Typography>
                <Link href="#" variant="caption" onClick={onReplyClick}>
                  {t("Components.Chat.Message.ViewThread", { count: message.childCount })}
                </Link>
              </Typography>
            )}
          </>
        }
        secondaryTypographyProps={{
          component: "div",
        }}
        sx={{ mt: 0, mb: 0 }}
      ></ListItemText>
      <ChatMessageToolbar
        onReplyClick={onReplyClick}
        onDeleteClick={onDeleteClick}
        open={isToolbarOpen}
        toolbarActions={toolbarActions}
      />
      <Dialog open={isDeleteDialogOpen} onClose={onDeleteCancel} fullWidth maxWidth="xs">
        <DialogTitle>{t("Components.Chat.Message.Delete")}</DialogTitle>
        <DialogContent>
          <Typography>{t("Components.Chat.Message.DeleteConfirmation")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDeleteCancel}>{t("Global.Action.Cancel")}</Button>
          <Button onClick={onDeleteConfirm} color="error">
            {t("Global.Action.Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </ListItem>
  );
}
