import { CommandActionType } from "@bigpi/cookbook";
import { createShapeId } from "@tldraw/tldraw";

import { createShapesAtEmptyPoint } from "BoardComponents/Utils/CreateShapeUtils";
import { ICommandRequestPlugInInputs } from "../WorkspaceBoardCommandManager";
import { RequestPlugInBase } from "./RequestPlugInBase";

export class HeadlineSimulatorRequestPlugIn extends RequestPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = CommandActionType.HeadlineSimulatorAction;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: ICommandRequestPlugInInputs): Promise<void> {
    const { app, commandRequest } = input;
    const { requestId } = commandRequest;

    if (!app) {
      console.warn(`No app instance for command request ${requestId}`);
      return;
    }

    const shapeUtil = app.shapeUtils.headline;
    createShapesAtEmptyPoint(
      app,
      [
        {
          id: createShapeId(requestId),
          type: "headline",
          isLocked: true,
          props: {
            ...shapeUtil?.getDefaultProps(),
            headlines: [],
            documentHtml: "",
            targetDocumentShapeId: "",
            likelihoodSentimentUnderThreshold: 0,
            sentimentThreshold: 0,
            scale: 1,
            // This is the default, but being explicit here
            status: "queued",
          },
        },
      ],
      null,
      true,
    );
  }
}
