import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useWorkspaceBoardQuery } from "GraphQL/Generated/Apollo";
import WorkspaceBoard from "./WorkspaceBoard";

export default function WorkspaceBoardPage() {
  const { t } = useTranslation();
  const params = useParams();
  const workspaceBoardId = params.workspaceBoardId || "";
  const { data, loading, error } = useWorkspaceBoardQuery({ variables: { id: workspaceBoardId } });

  const pageTitle = data?.workspaceBoard?.name || t("Pages.Workspaces.Title");
  const workspaceId = data?.workspaceBoard?.workspaceId;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      {workspaceBoardId && workspaceId && <WorkspaceBoard workspaceBoardId={workspaceBoardId} workspaceId={workspaceId} />}
    </>
  );
}
