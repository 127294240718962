import { useCallback, useState } from "react";

import { useUploadWorkspaceAttachmentsMutation } from "GraphQL/Generated/Apollo";
import { IFileWithId } from "../SelectedFilesListDialog";

/**
 * Hook to upload a batch of files to a workspace. Also tracks uploading, cancelled files & ability to abort the upload.
 *
 * @param workspaceId Workspace ID to upload attachments to
 * @returns Object with abortUpload, uploadingFiles, and uploadWorkspaceAttachmentsBatch
 */
export function useUploadWorkspaceAttachmentsBatch(workspaceId: string) {
  const [cancelledFiles, setCancelledFiles] = useState<Array<string>>([]);
  const [uploadingFiles, setUploadingFiles] = useState<Array<string>>([]);
  const [successFiles, setSuccessFiles] = useState<Array<string>>([]);
  const [failedFiles, setFailedFiles] = useState<Record<string, string>>({});
  const [abortControllers, setAbortControllers] = useState<Record<string, AbortController>>({});

  // Mutation
  const [uploadWorkspaceAttachments] = useUploadWorkspaceAttachmentsMutation();

  // Callback to upload a batch of files to a workspace
  const uploadBatch = useCallback(
    async (batch: Array<IFileWithId>) => {
      const controllers: Record<string, AbortController> = {};
      // Add files to uploadingFiles list
      setUploadingFiles((prev) => [...prev, ...batch.map((batchFile) => batchFile.id)]);

      const uploadPromises = batch.map((file) => {
        // AbortController to cancel the upload
        const controller = new AbortController();
        controllers[file.id] = controller;

        // Mutation to upload the file
        return uploadWorkspaceAttachments({
          variables: {
            input: {
              workspaceId,
              files: [
                {
                  id: file.id,
                  file: file.file,
                  fileSize: file.file.size,
                },
              ],
            },
          },
          context: {
            fetchOptions: {
              signal: controller.signal,
            },
          },
          refetchQueries: ["WorkspaceAttachments", "WorkspaceAttachmentAggregate", "Workspaces"],
        })
          .then((response) => {
            // On success remove the file from the uploading list
            setUploadingFiles((prev) => prev.filter((uploadingFile) => uploadingFile !== file.id));

            // Handle success files
            const successFiles: Array<string> = [];
            response.data?.uploadWorkspaceAttachments?.succeeded.forEach((succeededFile) => {
              successFiles.push(succeededFile.id);
            });
            setSuccessFiles((prev) => [...prev, ...successFiles]);

            // Handle failed files
            const failedFiles: Record<string, string> = {};
            response.data?.uploadWorkspaceAttachments?.failed.forEach((failedFile) => {
              failedFiles[failedFile.id] = failedFile.reason;
            });
            setFailedFiles((prev) => ({ ...prev, ...failedFiles }));
          })
          .catch((error) => {
            if (error.name !== "AbortError" && controller.signal.aborted === false) {
              console.error("Error uploading file:", file.id, error);
            }
            // On error remove the file from the uploading list
            setUploadingFiles((prev) => prev.filter((uploadingFile) => uploadingFile !== file.id));
            // Add file to cancelled files to retry
            setCancelledFiles((prev) => [...prev, file.id]);
          })
          .finally(() => {
            setUploadingFiles((prev) => prev.filter((uploadingFile) => uploadingFile !== file.id));
          });
      });

      setAbortControllers((prev) => ({ ...prev, ...controllers }));
      // Wait for all files to be uploaded
      await Promise.all(uploadPromises);
    },
    [workspaceId],
  );

  // Aborts current running upload (cancels the netowrk request)
  const abortUpload = useCallback(
    (fileId: string) => {
      const controller = abortControllers[fileId];
      controller?.abort();
    },
    [abortControllers],
  );

  return {
    abortUpload,
    cancelledFiles,
    failedFiles,
    successFiles,
    uploadingFiles,
    uploadWorkspaceAttachmentsBatch: uploadBatch,
  };
}
