import { ApolloError } from "@apollo/client";
import { AddOutlined, RemoveOutlined, ZoomOutOutlined } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { WorkspaceAttachmentsQuery } from "GraphQL/Generated/Apollo";
import WorkspaceAttachmentGridItem from "./WorkspaceAttachmentGridItem";
import { WorkspaceContentGridSize } from "./WorkspaceContentView";

export interface IWorkspaceAttachmentGridProps {
  error?: ApolloError;
  loading: boolean;
  gridSize: WorkspaceContentGridSize;
  onGridSizeChange: (gridSize: WorkspaceContentGridSize) => void;
  rows: WorkspaceAttachmentsQuery["workspaceAttachments"];
}

export default function WorkspaceAttachmentGrid(props: IWorkspaceAttachmentGridProps) {
  const { error, loading, rows, gridSize, onGridSizeChange } = props;

  const { t } = useTranslation();

  const increaseGridSize = () => {
    const newGridSize = gridSize === "small" ? "medium" : gridSize === "medium" ? "large" : "large";
    onGridSizeChange(newGridSize);
  };

  const decreaseGridSize = () => {
    const newGridSize = gridSize === "large" ? "medium" : gridSize === "medium" ? "small" : "small";
    onGridSizeChange(newGridSize);
  };

  return (
    <>
      {loading && <Typography variant="caption">{t("Global.Status.Loading")}</Typography>}
      {!loading && rows.length > 0 && (
        <>
          <Grid
            component="nav"
            container
            spacing={gridSize === "small" ? 1 : gridSize === "medium" ? 2 : 3}
            alignItems="stretch"
            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 24 }}
          >
            {rows.map((row) => {
              return <WorkspaceAttachmentGridItem key={row.id} workspaceAttachmentDetails={row} size={gridSize} />;
            })}
          </Grid>
          {rows.length > 0 && (
            <Box
              sx={{
                backgroundColor: "rgba(0,0,0,0.12)",
                border: "1px solid rgba(0,0,0,0.24)",
                display: "flex",
                alignItems: "center",
                position: "fixed",
                bottom: "2rem",
                left: "50%",
                transform: "translateX(-50%)",
                borderRadius: "2rem",
              }}
            >
              <IconButton color="primary" disabled={gridSize === "small"} onClick={decreaseGridSize}>
                <RemoveOutlined />
              </IconButton>
              <ZoomOutOutlined sx={{ color: "rgba(0,0,0,0.54)" }} />
              <IconButton color="primary" disabled={gridSize === "large"} onClick={increaseGridSize}>
                <AddOutlined />
              </IconButton>
            </Box>
          )}
        </>
      )}
      {!loading && !error && rows.length === 0 && (
        <Typography variant="caption" color="textSecondary">
          {t("Components.WorkspaceAttachmentGrid.NoItems")}
        </Typography>
      )}
      {error && (
        <Typography variant="caption" color="error.main">
          {t("Components.WorkspaceAttachmentGrid.LoadError")}
        </Typography>
      )}
    </>
  );
}
