import { useCallback, useEffect, useState } from "react";

import { useImageTokenQuery } from "GraphQL/Generated/Apollo";

// Keeping poll interval to 5 minutes, so that the user has at least 10 minutes
// for pasting the content
const DEFAULT_IMAGE_TOKEN_POLLING_TIME = 5 * 60000;

// User inactivity timeout is set to 5 minutes
const USER_INACTIVITY_TIMEOUT = 5 * 60000;

// List of events that are considered as user activity
const USER_ACTIVITY_EVENTS = [
  // Scrolling events
  "wheel",
  "mousewheel",
  "DOMMouseScroll" /* FIREFOX */,

  // Mouse cursor move events
  "mousemove",

  // Mouse click events
  "mousedown",

  // Keyboard key press events
  "keydown",

  // Touch events
  "touchmove",
  "touchstart",
];

/**
 * Custom hook to poll for image token every 5 minutes and stop polling when the user is inactive.
 */
export const useImageTokenPolling = () => {
  const {
    refetch: refetchImageToken,
    startPolling: startImageTokenPolling,
    stopPolling: stopImageTokenPolling,
  } = useImageTokenQuery({
    // We want to get the latest value from server every time the call is made
    fetchPolicy: "network-only",
  });

  const [isPageVisible, setIsPageVisible] = useState(true);
  const [isUserActive, setIsUserActive] = useState(true);

  useEffect(() => {
    const onPageVisibilityChange = () => {
      // If the page is not visible, mark the page as not visible
      if (document.hidden) {
        setIsPageVisible(false);
      } else {
        setIsPageVisible(true);
      }
    };

    // Listen for page visibility change events
    document.addEventListener("visibilitychange", onPageVisibilityChange);

    // Clear the event listener on unmount
    return () => {
      document.removeEventListener("visibilitychange", onPageVisibilityChange);
    };
  }, []);

  const onUserInactive = useCallback(() => {
    // Mark the user as inactive
    setIsUserActive(false);
  }, []);

  const onUserActive = useCallback(() => {
    // Mark the user as active
    setIsUserActive(true);
  }, []);

  useEffect(() => {
    // Start the inactivity timeout
    let inactivityTimeout = setTimeout(onUserInactive, USER_INACTIVITY_TIMEOUT);

    // If the user is active, reset the inactivity timeout and mark the user as active
    const onUserActivity = () => {
      onUserActive();

      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(onUserInactive, USER_INACTIVITY_TIMEOUT);
    };

    // Listen for user activity events
    USER_ACTIVITY_EVENTS.forEach((event) => {
      document.body.addEventListener(event, onUserActivity);
    });

    // Clear the inactivity timeout and remove the event listeners on unmount
    return () => {
      clearTimeout(inactivityTimeout);

      USER_ACTIVITY_EVENTS.forEach((event) => {
        document.body.removeEventListener(event, onUserActivity);
      });
    };
  }, [onUserInactive, onUserActive]);

  const refetchTokenAndStartPolling = useCallback(() => {
    refetchImageToken();
    startImageTokenPolling(DEFAULT_IMAGE_TOKEN_POLLING_TIME);
  }, [refetchImageToken, startImageTokenPolling]);

  useEffect(() => {
    // Start polling for image token only when the page is visible and the user is active
    if (isPageVisible && isUserActive) {
      refetchTokenAndStartPolling();
    }

    // Stop polling on unmount
    return () => {
      stopImageTokenPolling();
    };
  }, [isPageVisible, isUserActive, refetchTokenAndStartPolling, stopImageTokenPolling]);
};
