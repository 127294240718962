import * as React from "react";
import { ObjectRole } from "GraphQL/Generated/Apollo";
import { useAuthUser } from "@frontegg/react";
import { useWorkspaceAccessControlListUserRolesQuery } from "GraphQL/Generated/Apollo";

export interface HasWorkspaceRoleProps {
  children: React.ReactNode;
  roles: Array<ObjectRole>;
  workspaceId: string;
}

export const HasWorkspaceRole = (props: HasWorkspaceRoleProps) => {
  const { children, roles, workspaceId } = props;
  const user = useAuthUser();
  const { data, error, loading } = useWorkspaceAccessControlListUserRolesQuery({
    variables: {
      userId: user.id,
      workspaceId,
    },
  });

  if (data?.workspaceAccessControlListUserRoles.roles.some((assignedRole) => roles.includes(assignedRole as ObjectRole))) {
    return <>{children}</>;
  }
  return <></>;
};

export default HasWorkspaceRole;
