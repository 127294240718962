import DocumentAccessControlListEditor from "Components/AccessControlList/DocumentAccessControlListEditor";
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useDocumentQuery } from "GraphQL/Generated/Apollo";

export interface DocumentAccessControlListDialogProps {
  open: boolean;
  onClose: () => void;
  documentId: string;
}

export function DocumentAccessControlListDialog(props: DocumentAccessControlListDialogProps) {
  const { t } = useTranslation();
  const { onClose, open, documentId } = props;
  const { data, loading, error } = useDocumentQuery({ variables: { id: documentId } });
  const name = data?.document?.name || "";

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth={true} disableEscapeKeyDown={false}>
      <DialogTitle variant="h5">
        <Trans i18nKey="Components.DocumentAccessControlListDialog.Title">Share "{{ name }}"</Trans>
      </DialogTitle>
      <DialogContent>
        <DocumentAccessControlListEditor documentId={documentId} />
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DocumentAccessControlListDialog;
