import { useAuthUser } from "@frontegg/react";
import { CircularProgress, Fade, LinearProgress, Typography } from "@mui/material";
import {
  ObjectRole,
  useCreateWorkspaceAccessControlListMutation,
  useDeleteWorkspaceAccessControlListMutation,
  useWorkspaceAccessControlListByObjectQuery,
  useWorkspaceAccessControlListUserRolesQuery,
} from "GraphQL/Generated/Apollo";
import { useTranslation } from "react-i18next";

import AccessControlListDropdown from "Components/AccessControlList/AccessControlListDropdown";
import AccessControlListTable from "Components/AccessControlList/AccessControlListTable";
import HasWorkspaceRole from "Components/HasWorkspaceRole/HasWorkspaceRole";

export interface WorkspaceAccessControlListEditorProps {
  workspaceId: string;
}

export default function WorkspaceAccessControlListEditor(props: WorkspaceAccessControlListEditorProps) {
  const { workspaceId } = props;
  const { t } = useTranslation();
  const user = useAuthUser();
  const [createWorkspaceAccessControlListMutation, { data: createData, loading: createLoading, error: createError }] =
    useCreateWorkspaceAccessControlListMutation();
  const [deleteWorkspaceAccessControlListMutation, { data: deleteData, loading: deleteLoading, error: deleteError }] =
    useDeleteWorkspaceAccessControlListMutation();
  const { data, loading, error } = useWorkspaceAccessControlListByObjectQuery({
    variables: {
      workspaceId,
    },
    fetchPolicy: "no-cache",
  });
  const {
    data: aclData,
    error: aclError,
    loading: aclLoading,
  } = useWorkspaceAccessControlListUserRolesQuery({
    variables: {
      userId: user.id,
      workspaceId,
    },
  });

  // Check if we should be in read-only mode
  const readOnly =
    aclData?.workspaceAccessControlListUserRoles.roles.some((assignedRole) =>
      [ObjectRole.ContentManager, ObjectRole.Contributor, ObjectRole.Manager, ObjectRole.Owner].includes(assignedRole),
    ) !== true;

  function handleOnAddAcl(subjectId: string, subjectType: string, role: ObjectRole) {
    createWorkspaceAccessControlListMutation({
      variables: {
        input: {
          workspaceId: workspaceId,
          userId: subjectType === "User" ? subjectId : undefined,
          userGroupId: subjectType === "UserGroup" ? subjectId : undefined,
          role,
        },
      },
      refetchQueries: ["WorkspaceAccessControlListByObject", "Workspaces", "Workspace"],
    });
  }

  function handleOnDeleteAcl(id: string) {
    deleteWorkspaceAccessControlListMutation({
      variables: {
        input: {
          id,
        },
      },
      refetchQueries: ["WorkspaceAccessControlListByObject", "Workspaces", "Workspace"],
    });
  }

  const isLoading = loading || createLoading || deleteLoading || aclLoading;
  return (
    <>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {t("Components.WorkspaceAccessControlListEditor.Description")}
        <Fade
          in={isLoading}
          style={{
            transitionDelay: isLoading ? "800ms" : "0ms",
          }}
        >
          <LinearProgress />
        </Fade>
      </Typography>
      <AccessControlListTable
        rows={data?.workspaceAccessControlListByObject || []}
        onDelete={handleOnDeleteAcl}
        showActions={!readOnly}
      />
      <HasWorkspaceRole workspaceId={workspaceId} roles={[ObjectRole.Manager, ObjectRole.Owner]}>
        <AccessControlListDropdown allowEveryone={true} onAdd={handleOnAddAcl} />
      </HasWorkspaceRole>
    </>
  );
}
