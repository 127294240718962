import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ContentTypes } from "@bigpi/cookbook";
import { Permissions } from "@bigpi/permission";
import { Box, Button, Input, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";

import { AttachmentList } from "./AttachmentList";
import { CommandContext } from "CommandContext";
import HasPermission from "Components/HasPermission/HasPermission";
import { DocumentList } from "./DocumentList";
import { useAttachmentsQuery, useUploadAttachmentMutation, useUploadDocumentMutation } from "GraphQL/Generated/Apollo";
import { linkToId } from "RoutePaths";

import "./LibraryPage.css";

export default function LibraryPage() {
  const attachmentTypes = [ContentTypes.CSV.toString(), ContentTypes.JSON.toString(), ContentTypes.PDF.toString()];
  const documentTypes = [".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>();
  const [documentFile, setDocumentFile] = useState<File | null>(null);
  const [uploadDocumentMutation, { loading: uploadDocumentLoading }] = useUploadDocumentMutation();

  const { data: attachmentsData, loading: attachmentsLoading, error: attachmentsError } = useAttachmentsQuery();
  const [uploadAttachmentMutation, { loading: uploadAttachmentLoading }] = useUploadAttachmentMutation();

  // Set current application session context
  useEffect(() => {
    CommandContext.replaceSessionContext([]);
  }, []);

  const handleFileSelection = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { validity, files },
    } = e;

    if (validity.valid && files && files[0]) {
      setDocumentFile(files[0]);
    } else {
      setDocumentFile(null);
    }
  };

  const uploadDocument = async () => {
    if (!documentFile) {
      return;
    }

    const uploadVariables = {
      file: documentFile,
    };
    const uploadParams = {
      variables: uploadVariables,
      errorPolicy: undefined,
      refetchQueries: ["Attachments", "Documents"],
    };

    let uploadDocumentFile;
    if (attachmentTypes.includes(documentFile.type)) {
      uploadDocumentFile = uploadAttachmentMutation(uploadParams);
    } else {
      uploadDocumentFile = uploadDocumentMutation(uploadParams);
    }
    return uploadDocumentFile
      .then(() => {
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setDocumentFile(null);
        toast.success(`${documentFile?.name} - ${t("Pages.Library.UploadStatus.Success")}`);
      })
      .catch((error: Error) => {
        toast.error(`${documentFile?.name} - ${t("Pages.Library.UploadStatus.Failed")} - ${error.message}`);
      });
  };

  return (
    <>
      <Helmet>
        <title>{t("Pages.Library.Title")}</title>
      </Helmet>

      <Box
        sx={{
          display: "flex",
          m: 3,
          alignSelf: "center",
          flexDirection: "column",
          width: "80%",
          maxWidth: "960px",
          border: `1px solid #f1f1f1`,
          borderRadius: "16px",
        }}
      >
        <Box sx={{ display: "flex", flex: 0, p: 3, pb: 0, flexDirection: "row" }}>
          <Typography variant="h5" sx={{ flex: 1 }}>
            {t("Pages.Library.Documents.List.Title")}
          </Typography>
          <HasPermission permission={Permissions.PatronLibraryWrite}>
            <Input
              type="file"
              id="file"
              name="file"
              sx={{ mb: 2 }}
              disableUnderline
              inputRef={fileInputRef}
              inputProps={{
                style: { padding: 0, height: "36px" },
                accept: [...attachmentTypes, ...documentTypes],
              }}
              onChange={handleFileSelection}
            />
            <LoadingButton
              variant="contained"
              sx={{ ml: 2, mb: 2, mr: 2 }}
              disabled={!documentFile}
              onClick={uploadDocument}
              loading={uploadDocumentLoading || uploadAttachmentLoading}
            >
              {t("Global.Action.UploadDocument")}
            </LoadingButton>

            <Button startIcon={<AddIcon />} variant="contained" sx={{ mb: 2, width: "180px" }} href={linkToId("document", "new")}>
              {t("Global.Action.NewDocument")}
            </Button>
          </HasPermission>
        </Box>
        <Box sx={{ display: "flex", flex: 1, p: 3, flexDirection: "column" }}>
          <DocumentList />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          m: 3,
          alignSelf: "center",
          flexDirection: "column",
          width: "80%",
          maxWidth: "960px",
          border: `1px solid #f1f1f1`,
          borderRadius: "16px",
        }}
      >
        <Box sx={{ display: "flex", flex: 0, p: 3, pb: 0, flexDirection: "row" }}>
          <Typography variant="h5" sx={{ flex: 1 }}>
            {t("Pages.Library.Attachments.List.Title")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flex: 1, p: 3, flexDirection: "column" }}>
          <AttachmentList />
        </Box>
      </Box>
    </>
  );
}
