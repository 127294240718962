import { useAuthUser } from "@frontegg/react";
import { CircularProgress, Fade, LinearProgress, Typography } from "@mui/material";
import {
  ObjectRole,
  useCreateDocumentAccessControlListMutation,
  useDeleteDocumentAccessControlListMutation,
  useDocumentAccessControlListByObjectQuery,
  useDocumentAccessControlListUserRolesQuery,
} from "GraphQL/Generated/Apollo";
import { useTranslation } from "react-i18next";

import AccessControlListDropdown from "Components/AccessControlList/AccessControlListDropdown";
import AccessControlListTable from "Components/AccessControlList/AccessControlListTable";
import HasDocumentRole from "Components/HasDocumentRole/HasDocumentRole";

export interface DocumentAccessControlListEditorProps {
  documentId: string;
}

export default function DocumentAccessControlListEditor(props: DocumentAccessControlListEditorProps) {
  const { documentId } = props;
  const { t } = useTranslation();
  const user = useAuthUser();
  const [createDocumentAccessControlListMutation, { data: createData, loading: createLoading, error: createError }] =
    useCreateDocumentAccessControlListMutation();
  const [deleteDocumentAccessControlListMutation, { data: deleteData, loading: deleteLoading, error: deleteError }] =
    useDeleteDocumentAccessControlListMutation();
  const { data, loading, error } = useDocumentAccessControlListByObjectQuery({
    variables: {
      documentId,
    },
    fetchPolicy: "no-cache",
  });
  const {
    data: aclData,
    error: aclError,
    loading: aclLoading,
  } = useDocumentAccessControlListUserRolesQuery({
    variables: {
      userId: user.id,
      documentId,
    },
  });

  // Check if we should be in read-only mode
  const readOnly =
    aclData?.documentAccessControlListUserRoles.roles.some((assignedRole) =>
      [ObjectRole.ContentManager, ObjectRole.Contributor, ObjectRole.Manager, ObjectRole.Owner].includes(assignedRole),
    ) !== true;

  function handleOnAddAcl(subjectId: string, subjectType: string, role: ObjectRole) {
    createDocumentAccessControlListMutation({
      variables: {
        input: {
          documentId: documentId,
          userId: subjectType === "User" ? subjectId : undefined,
          userGroupId: subjectType === "UserGroup" ? subjectId : undefined,
          role,
        },
      },
      refetchQueries: ["DocumentAccessControlListByObject", "Documents", "Document"],
    });
  }

  function handleOnDeleteAcl(id: string) {
    deleteDocumentAccessControlListMutation({
      variables: {
        input: {
          id,
        },
      },
      refetchQueries: ["DocumentAccessControlListByObject", "Documents", "Document"],
    });
  }

  const isLoading = loading || createLoading || deleteLoading || aclLoading;
  return (
    <>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {t("Components.DocumentAccessControlListEditor.Description")}
        <Fade
          in={isLoading}
          style={{
            transitionDelay: isLoading ? "800ms" : "0ms",
          }}
        >
          <LinearProgress />
        </Fade>
      </Typography>
      <AccessControlListTable
        rows={data?.documentAccessControlListByObject || []}
        onDelete={handleOnDeleteAcl}
        showActions={!readOnly}
      />
      <HasDocumentRole documentId={documentId} roles={[ObjectRole.Manager, ObjectRole.Owner]}>
        <AccessControlListDropdown allowEveryone={true} onAdd={handleOnAddAcl} />
      </HasDocumentRole>
    </>
  );
}
