export enum CommandActionType {
  /**
   * Chat message received.
   */
  ChatMessageAction = "ChatMessageAction",

  /**
   * Run a client side action.
   */
  GenericClientAction = "GenericClientAction",

  /**
   * Generates distilled content for the given transcript.
   */
  GenerateDistilledTranscriptAction = "GenerateDistilledTranscriptAction",

  /**
   * Generate ThemeDiscussionAnalysis data for the given document.
   */
  GenerateThemeDiscussionAnalysisAction = "GenerateThemeDiscussionAnalysisAction",

  /**
   * Generate simulated headlines for the given text.
   */
  HeadlineSimulatorAction = "HeadlineSimulatorAction",

  /**
   * Generates responses using predefined HTML prompts.
   */
  HtmlPrompterAction = "HtmlPrompterAction",

  /**
   * Run a GraphQL query against an internal service.
   */
  InternalGraphQLQuery = "InternalGraphQLQuery",

  /**
   * Command pipeline aliveness ping.
   */
  PingAction = "PingAction",

  /**
   * A generic text processing action with specific parameters defining the desired behavior.
   */
  ProcessTextAction = "ProcessTextAction",
}
