import {
  COMMAND_GENERATE_DISTILLED_TRANSCRIPT_TEMPLATE,
  ICommandRequest,
  TranscriptSession,
  getObjectHash,
} from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import { Box, CircularProgress, FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CommandContext } from "CommandContext";
import { useCommandExecutor } from "Components/CommandManagers/useCommandExecutor";
import { useDistilledTranscriptQuery, useTranscriptQuery } from "GraphQL/Generated/Apollo";
import DistilledTranscriptSession from "BoardComponents/FeedShape/DistilledTranscriptSession";
import FullTranscriptSession from "BoardComponents/FeedShape/FullTranscriptSession";

const AUTO_REFRESH_TIMEOUT = 10000;

export interface IDistilledTranscriptProps {
  transcriptId: string;
}

export const DistilledTranscript = (props: IDistilledTranscriptProps) => {
  const { transcriptId } = props;
  const { t } = useTranslation();
  const user = useAuthUser();
  const commandExecutor = useCommandExecutor();
  const [showDistilledTranscript, setShowDistilledTranscript] = useState(true);
  const {
    loading: distilledTranscriptLoading,
    error: distilledTranscriptError,
    data: distilledTranscriptData,
    startPolling,
    stopPolling,
  } = useDistilledTranscriptQuery({
    variables: {
      transcriptId,
    },
  });
  const {
    data: fullTranscriptData,
    error: fullTranscriptError,
    loading: fullTranscriptLoading,
  } = useTranscriptQuery({
    variables: {
      id: transcriptId,
    },
  });

  // Manage polling depending on whether data is available or not
  useEffect(() => {
    if (distilledTranscriptData?.distilledTranscript === null) {
      startPolling(AUTO_REFRESH_TIMEOUT);
    } else {
      stopPolling();
    }
  }, [distilledTranscriptData]);

  // Kick off a request for data generation if it doesn't exist yet
  useEffect(() => {
    if (distilledTranscriptData?.distilledTranscript === null) {
      const command = {
        ...COMMAND_GENERATE_DISTILLED_TRANSCRIPT_TEMPLATE,
      };

      // Create the overrides/values that are not set automatically by CommandExecutor
      const commandRequest: Partial<ICommandRequest> = {
        // Create deduplicationId
        deduplicationId: getObjectHash({
          commandId: command.id,
          organizationId: user?.tenantId,
          transcriptId,
        }),
      };

      // Set the selected transcript into the context to be picked up from there
      CommandContext.patchCommandContext({
        selection: {
          transcriptId,
        },
      });

      commandExecutor.executeCommand(command, commandRequest);
    }
  }, [distilledTranscriptData]);

  if (!distilledTranscriptData?.distilledTranscript && !distilledTranscriptLoading && !distilledTranscriptError) {
    return (
      <Typography variant="body1" gutterBottom>
        {t("Components.DistilledTranscriptDetails.Generating")}
        <span className="ellipsis-anim">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </Typography>
    );
  }

  const fullManagementDiscussion = fullTranscriptData?.transcript?.transcriptItems.filter(
    (transcriptItem) => transcriptItem.session === TranscriptSession.ManagementDiscussion,
  );
  const distilledManagementDiscussion = distilledTranscriptData?.distilledTranscript?.distilledSegments.filter(
    (distilledSegment) => distilledSegment.session === TranscriptSession.ManagementDiscussion,
  );
  const fullQna = fullTranscriptData?.transcript?.transcriptItems.filter(
    (transcriptItem) => transcriptItem.session === TranscriptSession.QuestionAnswer,
  );
  const distilledQna = distilledTranscriptData?.distilledTranscript?.distilledSegments.filter(
    (distilledSegment) => distilledSegment.session === TranscriptSession.QuestionAnswer,
  );

  return (
    <>
      {distilledTranscriptLoading || fullTranscriptLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : distilledTranscriptError || fullTranscriptError ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" gutterBottom>
            {t("Components.DistilledTranscriptView.FetchFailureMessage")}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              width: "95%",
              marginLeft: "-24px",
              backgroundColor: "white",
              marginTop: "-60px",
              paddingY: "5px",
              paddingRight: "24px",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    value={showDistilledTranscript}
                    onChange={(_event, checked) => setShowDistilledTranscript(checked)}
                    defaultChecked
                  />
                }
                label={
                  showDistilledTranscript
                    ? t("Components.DistilledTranscriptDetails.Distilled")
                    : t("Components.DistilledTranscriptDetails.Full")
                }
                labelPlacement="start"
              />
            </FormGroup>
          </Box>
          {showDistilledTranscript ? (
            <Box>
              <DistilledTranscriptSession
                title={t("Components.DistilledTranscriptDetails.ManagementDiscussionLabel")}
                distilledSegments={distilledManagementDiscussion}
              />
              <DistilledTranscriptSession
                title={t("Components.DistilledTranscriptDetails.QnALabel")}
                distilledSegments={distilledQna}
              />
            </Box>
          ) : (
            <Box>
              <FullTranscriptSession
                title={t("Components.DistilledTranscriptDetails.ManagementDiscussionLabel")}
                transcriptItems={fullManagementDiscussion}
              />
              <FullTranscriptSession title={t("Components.DistilledTranscriptDetails.QnALabel")} transcriptItems={fullQna} />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
