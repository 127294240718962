import { getAssetUrlsByImport } from "@tldraw/assets/imports";
import {
  Editor,
  TLAnyShapeUtilConstructor,
  Tldraw,
  TLStateNodeConstructor,
  TLStoreWithStatus,
  TLUiOverrides,
} from "@tldraw/tldraw";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import DocumentToolIcon from "Assets/Icons/tool-document.svg";
import InteractToolIcon from "Assets/Icons/tool-interact.svg";
import { BoardProviderContext } from "BoardComponents/BoardProviderContext/BoardProviderContext";
import { BoardProvider } from "BoardComponents/BoardProviderContext/BoardProvider";
import { Companion } from "BoardComponents/Companion/Companion";
import { CompanionManager } from "BoardComponents/Companion/CompanionManager";
import { CompanionManagerContext } from "BoardComponents/Companion/CompanionManagerContext";
import { HoveredShapeIndicator, TldrawSelectionForeground } from "BoardComponents/Tools";
import { AlertBar } from "Components/AlertBar/AlertBar";
import { FatalErrorDialog } from "Components/FatalErrorBoundary/FatalErrorDialog";
import { ContextMenu } from "./ContextMenu";
import { CustomStylePanel } from "./CustomStylePanel";
import { HelpMenu } from "./HelpMenu";
import { KeyboardShortcutsDialog } from "./KeyboardShortcutsDialog";
import { MainMenu } from "./MainMenu";
import { CustomPageMenu } from "./CustomPageMenu";
import { QuickActions } from "./QuickActions";
import { Toolbar } from "./Toolbar";
import { IYjsStoreWithStatus } from "./useYjsStore";

export interface CollaborativeBoardEditorProps {
  customShapeUtils?: TLAnyShapeUtilConstructor[];
  customTools?: TLStateNodeConstructor[];
  syncedStore: TLStoreWithStatus;
  onMount?: (editor: Editor) => void;
  openRenameDialog: () => void;
  openAclDialog: () => void;
  activateThumbnailFrame: () => void;
}

export const CollaborativeBoardEditor = memo((props: CollaborativeBoardEditorProps) => {
  const { customShapeUtils, customTools, syncedStore, onMount, openRenameDialog, openAclDialog, activateThumbnailFrame } = props;
  const boardProvider = BoardProvider.useNewBoardProvider();
  const companionManager = CompanionManager.useNewCompanionManager();

  const { t } = useTranslation();

  const uiOverrides: TLUiOverrides = {
    actions(app, schema, helpers) {
      // Disable various keyboard shortcuts
      schema["toggle-dark-mode"].kbd = undefined;

      // Add action for board search
      schema["find-in-board"] = {
        id: "find-in-board",
        label: "action.findInBoard" as any,
        kbd: "$f",
        readonlyOk: true,
        onSelect: () => {
          // Trigger the board search dialog using KeyboardEvent
          // apps/patron/src/BoardComponents/BoardSearchManager/BoardSearchManager.tsx -> useEffect -> onKeyDown
          window.document.dispatchEvent(new KeyboardEvent("keydown", { keyCode: 70, metaKey: true }));
        },
      };

      return schema;
    },
    tools(app, tools) {
      tools.htmlDocument = {
        id: "htmlDocument",
        icon: "tool-document" as any,
        label: "tool.document" as any,
        kbd: "n",
        readonlyOk: false,
        onSelect: () => {
          app.setCurrentTool("htmlDocument");
        },
      };

      tools.interact = {
        id: "interact",
        icon: "tool-interact" as any,
        label: "tool.interact" as any,
        kbd: "i",
        readonlyOk: false,
        onSelect: () => {
          app.setCurrentTool("interact");
        },
      };

      return tools;
    },
    translations: {
      en: {
        "action.captureThumbnail": t("Global.Menu.CaptureThumbnail"),
        "action.findInBoard": t("Global.Menu.FindInBoard"),
        "action.rename": t("Global.Menu.Rename"),
        "action.share": t("Global.Menu.Share"),
        "tool.text": t("Tools.TextLabel"),
        "tool.document": t("Tools.Document"),
        "tool.interact": t("Tools.Interact"),
      },
    },
  };

  const assetUrls = getAssetUrlsByImport();

  // @ts-expect-error - Tldraw strongly types its own available icons, but we're adding our own
  assetUrls.icons["tool-document"] = DocumentToolIcon;
  // @ts-expect-error - Tldraw strongly types its own available icons, but we're adding our own
  assetUrls.icons["tool-interact"] = InteractToolIcon;

  const isConnected =
    syncedStore.status === "loading" || (syncedStore.status === "synced-remote" && syncedStore.connectionStatus === "online");

  boardProvider.yDocument = (syncedStore as IYjsStoreWithStatus).yDocument || null;
  boardProvider.provider = (syncedStore as IYjsStoreWithStatus).provider || null;

  return (
    <BoardProviderContext.Provider value={boardProvider}>
      <CompanionManagerContext.Provider value={companionManager}>
        <div className="tldraw__editor">
          <Tldraw
            onMount={onMount}
            components={{
              // disable app-level error boundaries:
              ErrorFallback: (error, editor) => {
                const errorToShow = error?.error || error;
                return <FatalErrorDialog error={errorToShow} />;
              },
              HoveredShapeIndicator,
              KeyboardShortcutsDialog,
              SelectionForeground: TldrawSelectionForeground,
              Toolbar,
              HelpMenu,
              ContextMenu,
              MainMenu: () => (
                <MainMenu
                  activateThumbnailFrame={activateThumbnailFrame}
                  openAclDialog={openAclDialog}
                  openRenameDialog={openRenameDialog}
                />
              ),
              StylePanel: CustomStylePanel,
              QuickActions,
              // Disable the actions menu - This is the menu that contains "align", "distribute", etc.
              ActionsMenu: () => null,
              PageMenu: CustomPageMenu,
            }}
            shapeUtils={customShapeUtils}
            tools={customTools}
            store={syncedStore}
            overrides={uiOverrides}
            assetUrls={assetUrls}
            autoFocus
          />
          <Companion />
        </div>

        {!isConnected && (
          <AlertBar isOnline={false} isModal={true} message={t("Components.WorkspaceBoard.Disconnected")} minWidth={160} />
        )}
      </CompanionManagerContext.Provider>
    </BoardProviderContext.Provider>
  );
});

export default CollaborativeBoardEditor;
