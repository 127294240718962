import Box from "@mui/material/Box";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserGroupList from "./UserGroupList";
import { Button } from "@mui/material";

import { CommandContext } from "CommandContext";
import { linkToId } from "RoutePaths";

export default function UserGroupsPage() {
  const { t } = useTranslation();

  // Set current application session context
  CommandContext.replaceSessionContext([]);

  return (
    <>
      <Helmet>
        <title>{t("Pages.UserGroups.Title")}</title>
      </Helmet>
      <Box sx={{ display: "flex", flex: 1, p: 3, flexDirection: "column" }}>
        <Button variant="contained" sx={{ mb: 2, maxWidth: "200px", alignSelf: "end" }} href={linkToId("userGroup", "new")}>
          {t("Components.AdminSidebar.NewUserGroup")}
        </Button>

        <UserGroupList />
      </Box>
    </>
  );
}
