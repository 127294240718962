import { IPlugIn } from "@bigpi/cookbook";
import { ICommandResponsePlugInInputs } from "../WorkspaceBoardCommandManager";
import {
  BoardSearchResultResponsePlugIn,
  HeadlineSimulatorResponsePlugIn,
  HtmlDocumentResponsePlugIn,
  SearchResultResponsePlugIn,
} from ".";

/**
 * Creates the plug-ins.
 *
 * @param options Options for the plug-ins.
 *
 * @returns An array of plug-ins to be registered.
 */
export function createCommandResponsePlugIns(): IPlugIn<ICommandResponsePlugInInputs, undefined | JSX.Element>[] {
  return [
    new BoardSearchResultResponsePlugIn(),
    new HeadlineSimulatorResponsePlugIn(),
    new HtmlDocumentResponsePlugIn(),
    new SearchResultResponsePlugIn(),
  ];
}
