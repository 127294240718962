export enum LinecookTaskType {
  /**
   * A request to execute an asynchronous command.
   */
  CommandRequest = "CommandRequest",

  /**
   * A response to an asynchronous command request, either final or intermediate response.
   */
  CommandResponse = "CommandResponse",

  /**
   * Convert document to HTML.
   */
  ConvertToHtml = "ConvertToHtml",

  /**
   * Convert workspace attachment to HTML.
   */
  ConvertWorkspaceAttachmentToHtml = "ConvertWorkspaceAttachmentToHtml",

  /**
   * Index workspace attachment for search.
   */
  IndexWorkspaceAttachmentForSearch = "IndexWorkspaceAttachmentForSearch",

  /**
   * Index workspace board content for search.
   */
  IndexWorkspaceBoardContentForSearch = "IndexWorkspaceBoardContentForSearch",

  /**
   * Ingest an HTML document into Pantry.
   */
  IngestHtml = "IngestHtml",

  /**
   * Ingest workspace attachment HTML document.
   */
  IngestWorkspaceAttachmentHtml = "IngestWorkspaceAttachmentHtml",

  /**
   * Ingest a PDF attachment and index in Elasticsearch.
   */
  // TODO: 2023-11-17 (TB): Disabled due to pipeline not taking attachment permissions into account
  // IngestPdfAttachment = "IngestPdfAttachment",

  /**
   * Ingest a content from subscribed source into Elasticsearch.
   */
  IngestSubscriptionContent = "IngestSubscriptionContent",

  /**
   * Ingest a PressRelease document into Elasticsearch.
   */
  IngestPressRelease = "IngestPressRelease",

  /**
   * Ingest a Transcript document into Elasticsearch.
   */
  IngestTranscript = "IngestTranscript",
}
