import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridPaginationModel,
  GridSortModel,
  GridCallbackDetails,
} from "@mui/x-data-grid-premium";

import { linkToId } from "RoutePaths";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { USER_GROUPS_QUERY } from "GraphQL/UserGroup";

const DEFAULT_PAGE_SIZE = 10;

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    renderCell: (params: GridRenderCellParams<GridRowModel>) => (
      <Link to={`${linkToId("userGroup", params.row.id)}`}>{params.value}</Link>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created",
    minWidth: 200,
  },
];

export default function UserGroupList() {
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<Record<string, string>>({});
  const [page, setPage] = useState<number>(0);
  const { data, loading, fetchMore, error } = useQuery(USER_GROUPS_QUERY, {
    variables: {
      limit: DEFAULT_PAGE_SIZE,
      offset,
      orderBy,
    },
  });

  const rows = data?.userGroups || [];
  const count = data?.userGroupAggregate.count || 0;

  return (
    <DataGridPremium
      autoHeight
      checkboxSelection
      loading={loading}
      rows={rows}
      rowCount={count}
      columns={columns}
      paginationModel={{ page, pageSize: DEFAULT_PAGE_SIZE }}
      paginationMode="server"
      pagination={true}
      pageSizeOptions={[DEFAULT_PAGE_SIZE]}
      onPaginationModelChange={({ page }: GridPaginationModel) => {
        // TODO: Set limit on last page to avoid unnecessary network call if we don't have a full page
        setOffset(DEFAULT_PAGE_SIZE * page);
        setPage(page);
      }}
      sortingMode="server"
      onSortModelChange={(model: GridSortModel, details: GridCallbackDetails) => {
        const newOrderBy: Record<string, string> = {};

        if (model.length > 0 && model[0].sort) {
          newOrderBy[model[0].field] = model[0].sort;
        }

        setOrderBy(newOrderBy);
      }}
    />
  );
}
