import { Avatar } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

export interface UserAvatarProps {
  sx?: SxProps<Theme>;
  user?: {
    id: string;
    name: string;
    profilePictureUrl?: string | null;
  } | null;
  variant?: "rounded" | "circular";
}

export default function UserAvatar(props: UserAvatarProps) {
  const { sx, user, variant } = props;
  if (!user || !user.id) {
    return null;
  }

  const nameParts = user.name ? user.name.split(" ") : ["", ""];
  const initials = `${nameParts[0].substring(0, 1)}${
    nameParts.length > 1 ? nameParts[1].substring(0, 1) : nameParts[0].substring(1, 2)
  }`;
  return (
    <Avatar
      alt={user.name}
      src={user.profilePictureUrl ? user.profilePictureUrl || undefined : undefined}
      sx={[
        {
          border: "1px solid #f3f3f3",
          fontSize: "0.75rem",
          height: 24,
          width: 24,
        },
        ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
      ]}
      variant={variant}
    >
      {initials}
    </Avatar>
  );
}
