import { ONE_MB, WORKSPACE_ATTACHMENT_UPLOAD_MAX_FILE_SIZE } from "@bigpi/cookbook";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useWorkspaceAttachmentsQuery } from "GraphQL/Generated/Apollo";
import WorkspaceAttachmentGrid from "./WorkspaceAttachmentGrid";
import WorkspaceAttachmentList from "./WorkspaceAttachmentList";
import { WorkspaceContentGridSize } from "./WorkspaceContentView";

const DEFAULT_PAGE_SIZE = 20;

export interface IWorkspaceAttachmentViewProps {
  gridSize: WorkspaceContentGridSize;
  onGridSizeChange: (gridSize: WorkspaceContentGridSize) => void;
  viewType: "list" | "grid";
  workspaceId: string;
  searchValue: string;
}

export default function WorkspaceAttachmentView(props: IWorkspaceAttachmentViewProps) {
  const { gridSize, onGridSizeChange, viewType, workspaceId, searchValue } = props;

  const { t } = useTranslation();

  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<Record<string, string>>({ updatedAt: "desc" });

  const {
    data: workspaceAttachmentsData,
    loading: workspaceAttachmentsLoading,
    fetchMore: fetchMoreWorkspaceAttachments,
    error: workspaceAttachmentsError,
  } = useWorkspaceAttachmentsQuery({
    variables: {
      workspaceId,
      limit: DEFAULT_PAGE_SIZE,
      offset,
      orderBy,
      filters: {
        searchTerm: searchValue,
      },
    },
  });
  const attachments = workspaceAttachmentsData?.workspaceAttachments || [];
  const attachmentCount = workspaceAttachmentsData?.workspaceAttachmentAggregate.count || 0;

  if (attachmentCount === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          minHeight: "300px",
        }}
      >
        <UploadFileIcon sx={{ fontSize: "xx-large", mb: 2, color: "#0b3d62" }} />
        {t("Components.SelectedFilesListDialog.DragAndDrop")}
        <Typography variant="body1" color="GrayText">
          {t("Components.SelectedFilesListDialog.FileTypeAndSize", {
            maxInMBs: WORKSPACE_ATTACHMENT_UPLOAD_MAX_FILE_SIZE / ONE_MB,
          })}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minHeight: "200px" }}>
      <Box sx={{ display: "flex", p: 3, justifyContent: "center" }}>
        {viewType === "list" ? (
          <WorkspaceAttachmentList rows={attachments} error={workspaceAttachmentsError} loading={workspaceAttachmentsLoading} />
        ) : (
          <WorkspaceAttachmentGrid
            rows={attachments}
            error={workspaceAttachmentsError}
            loading={workspaceAttachmentsLoading}
            gridSize={gridSize}
            onGridSizeChange={onGridSizeChange}
          />
        )}
      </Box>

      {attachmentCount > attachments.length && (
        <Button
          sx={{ alignSelf: "center", maxWidth: "150px" }}
          onClick={() => {
            fetchMoreWorkspaceAttachments({
              variables: {
                offset: attachments.length,
                filters: {
                  searchTerm: searchValue,
                },
              },
            });
          }}
        >
          {t("Components.WorkspaceAttachmentView.ShowMore")}
        </Button>
      )}
    </Box>
  );
}
