export enum IndexDocumentType {
  /**
   * Workspace attachment.
   */
  Attachment = "Attachment",

  /**
   * Workspace board shape.
   */
  BoardShape = "BoardShape",

  /**
   * Transcript.
   */
  Transcript = "Transcript",
}
