import { Autocomplete, TextField } from "@mui/material";
import { SortOrder } from "GraphQL/Generated/Apollo";
import React from "react";
import { useUsersQuery, useUserGroupsQuery } from "GraphQL/Generated/Apollo";
import { useTranslation } from "react-i18next";

export interface UserDropdownProps {
  onChange?: (subjectId: string | null) => void;
  value: string | null;
}

export interface SubjectOptionType {
  label: string;
  value: string;
}

export default function UserDropdown(props: UserDropdownProps) {
  const { onChange, value } = props;
  const { t } = useTranslation();
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useUsersQuery({
    variables: {
      orderBy: {
        name: SortOrder.Asc,
      },
    },
  });
  const users = userData?.users || [];
  let subjects: Array<SubjectOptionType> = users.map((user) => ({ label: user.name, value: user.id, type: "User" }));
  const [subject, setSubject] = React.useState<SubjectOptionType | null>(null);

  React.useEffect(() => {
    let currentValue = subjects.filter(({ value: subjectId }) => subjectId === value);
    setSubject(currentValue.length > 0 ? currentValue[0] : null);
  }, [userData]);

  function handleOnChange(event: any, newValue: SubjectOptionType | null) {
    setSubject(newValue);
    if (onChange) {
      onChange(newValue?.value ?? null);
    }
  }

  if (userLoading) {
    return <></>;
  } else {
    return (
      <Autocomplete
        options={subjects}
        sx={{ width: 300 }}
        onChange={handleOnChange}
        value={subject}
        isOptionEqualToValue={(option, value) => option.value == value.value}
        renderInput={(params) => <TextField {...params} label={t("Components.UserDropdown.Placeholder")} />}
      />
    );
  }
}
