import { CommandActionType } from "@bigpi/cookbook";
import { createShapeId } from "@tldraw/tldraw";

import { HtmlDocumentRequestPlugInBase } from "./HtmlDocumentRequestPlugInBase";
import { ICommandRequestPlugInInputs } from "../WorkspaceBoardCommandManager";

export class HtmlPrompterRequestPlugIn extends HtmlDocumentRequestPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = CommandActionType.HtmlPrompterAction;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: ICommandRequestPlugInInputs): Promise<void> {
    const { app, commandRequest, t } = input;
    const { requestId } = commandRequest;

    if (!app) {
      console.warn(`No app instance for command Request: ${commandRequest.requestId}`);
      return;
    }

    this.upsertShape(app, t, {
      bindOptions: undefined,
      html: "",
      shapeId: createShapeId(requestId),
      sourceIds: [],
      status: "queued",
    });
  }
}
