import { Box, IconButton, useTheme } from "@mui/material";

import { Reply, Delete } from "@mui/icons-material";

export enum ToolbarActionsEnum {
  Delete = "Delete",
  Reply = "Reply",
  Reaction = "Reaction",
}

export interface ChatMessageToolbarProps {
  onDeleteClick: () => void;
  onReplyClick: () => void;
  open: boolean;
  toolbarActions: Array<ToolbarActionsEnum>;
}

export default function ChatMessageToolbar(props: ChatMessageToolbarProps) {
  const { onDeleteClick, onReplyClick, open, toolbarActions } = props;
  const theme = useTheme();

  if (!open || toolbarActions.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        display: "flex",
        pointerEvents: "auto",
        position: "absolute",
        right: 10,
      }}
    >
      {toolbarActions.map((action, index) => renderToolbarAction(action, onToolbarActionClick, index))}
    </Box>
  );

  function onToolbarActionClick(actionType: ToolbarActionsEnum) {
    switch (actionType) {
      case ToolbarActionsEnum.Reply:
        onReplyClick();
        break;
      case ToolbarActionsEnum.Delete:
        onDeleteClick();
        break;
      default:
        break;
    }
  }
}

function renderToolbarAction(action: ToolbarActionsEnum, onClick: (actionType: ToolbarActionsEnum) => void, index: number) {
  switch (action) {
    case ToolbarActionsEnum.Reply:
      return (
        <IconButton onClick={onClick.bind(undefined, ToolbarActionsEnum.Reply)} size="small" key={index}>
          <Reply fontSize="inherit" />
        </IconButton>
      );
    case ToolbarActionsEnum.Delete:
      return (
        <IconButton onClick={onClick.bind(undefined, ToolbarActionsEnum.Delete)} size="small" key={index}>
          <Delete fontSize="inherit" />
        </IconButton>
      );
    default:
      return null;
  }
}
