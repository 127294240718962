import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { FronteggProvider } from "@frontegg/react";
import { LicenseInfo } from "@mui/x-license-pro";

import "I18n/Config";
import AuthenticatedApolloProvider from "GraphQL/AuthenticatedApolloProvider";
import { initializeActionMenuFactory, initializeToolbarFactory } from "Editor";
import Config from "./Config";
import App from "./App";

import "Assets/Css/index.css";
import "Assets/Css/colors.css";
import "Assets/Css/layers.css";
import "Assets/Fonts/graphik/fonts.css";
import "Assets/Css/app.css";

// Add declartion for Freshdesk widget
declare global {
  function FreshworksWidget(action: string, ...args: any[]): void;
}

LicenseInfo.setLicenseKey(Config.muiXPremiumLicense);

const contextOptions = {
  baseUrl: Config.fronteggBaseUrl,
  clientId: Config.fronteggClientId,
  // Create a tenant resolver for Frontegg / special organizations, such as testing / uptime monitoring
  tenantResolver: () => {
    const urlQueryParams = new URLSearchParams(window.location.search);
    const organization = urlQueryParams.get("organization");
    return {
      tenant: organization,
    };
  },
};

// Initialize global ToolbarFactory
initializeToolbarFactory();

// Initialize global ActionMenuFactory
initializeActionMenuFactory();

const root = createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <FronteggProvider contextOptions={contextOptions}>
        <AuthenticatedApolloProvider>
          <App />
        </AuthenticatedApolloProvider>
      </FronteggProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
