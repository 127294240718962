import { ApolloError } from "@apollo/client";
import { useAuthUser } from "@frontegg/react";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { WorkspaceTagsList } from "Components/WorkspaceTagsList/WorkspaceTagsList";
import { WorkspacesQuery } from "GraphQL/Generated/Apollo";
import { linkToId } from "RoutePaths";
import { formatCollaborators } from "Utils/WorkspaceUtils";
import WorkspaceListMenu from "./WorkspaceListMenu";

export interface IWorkspaceListProps {
  loading: boolean;
  rows: WorkspacesQuery["workspaces"];
  error?: ApolloError;
}

export function WorkspaceList(props: IWorkspaceListProps) {
  const { loading, rows, error } = props;

  const user = useAuthUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      {loading && <Typography variant="caption">{t("Global.Status.Loading")}</Typography>}
      {!loading && rows.length > 0 && (
        <List
          component="nav"
          aria-label={t("Pages.Workspaces.List.AvailableWorkspaces")}
          sx={{ maxWidth: "960px", display: "flex", flexDirection: "column", flex: 1 }}
        >
          {rows.map((row) => {
            return (
              <ListItem key={row.id} secondaryAction={<WorkspaceListMenu workspaceId={row.id} />} disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(linkToId("workspace", row.id));
                  }}
                >
                  <ListItemIcon>
                    <WorkspacesIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={row.name}
                    primaryTypographyProps={{ fontWeight: "500" }}
                    secondary={
                      <Box sx={{ display: "flex", flexDirection: "column" }} component="span">
                        {formatCollaborators(user.id, row.workspaceAccessControlList, t)}
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} component="span">
                          <WorkspaceTagsList tagsList={row.tags} />
                        </Box>
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
      {!loading && !error && rows.length === 0 && (
        <Typography variant="caption" color="textSecondary">
          {t("Pages.Workspaces.List.NoItems")}
        </Typography>
      )}
      {error && (
        <Typography variant="caption" color="error.main">
          {t("Pages.Workspaces.List.LoadError")}
        </Typography>
      )}
    </>
  );
}
