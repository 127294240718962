import {
  AnalysisFieldTypeEnum,
  FacetDisplaySortType,
  IAnalysisFieldConfig,
  IDateRange,
  IDateRangeLimits,
  ISelectInputFieldOption,
} from "@bigpi/cookbook";
import { FormControl, FormControlOwnProps, FormControlProps, Grid, InputLabel, Select, SelectProps } from "@mui/material";
import { Box } from "@mui/system";
import { useValue } from "@tldraw/tldraw";

import { useTranslation } from "react-i18next";

import { IAnalysisFacets } from "BoardComponents/Types";
import { DataDateRangePicker, DataDateRangePickerProps } from "Components/Charting/Elements/DataDateRangePicker";
import { renderMenuItems } from "Components/MenuItems/MenuItemList";

/*********************************
 * Types/Interfaces
 *********************************/
interface AnalysisToolbarFormElementsProps<TFacets> {
  dateRangeShortcuts?: DataDateRangePickerProps["shortcutItems"];
  fieldInputValues: Record<keyof TFacets, IDateRangeLimits | Array<ISelectInputFieldOption>>;
  fields: Array<keyof TFacets>;
  fieldsConfig: Array<IAnalysisFieldConfig>;
  fieldSelectedValues: TFacets;
  fieldsSort?: Record<string, FacetDisplaySortType>;
  isSelectAllEnabled?: boolean;
  isSortEnabled?: boolean;
  onFieldChange: (
    field: string,
    value: Array<string> | string | { dateRange: IDateRange; shortcut?: string },
    inputValues?: Array<ISelectInputFieldOption> | IDateRangeLimits,
  ) => void;
  onSortChange?: (field: string, value: FacetDisplaySortType) => void;
  slotProps?: {
    dataDateRangePicker?: Partial<DataDateRangePickerProps> & {
      formControlSize?: FormControlProps["size"];
    };
    select: {
      hideLabel?: boolean;
      sx?: SelectProps["sx"];
      formControlSize?: FormControlProps["size"];
    };
  };
}

/*********************************
 * Component
 *********************************/
export function AnalysisToolbarFormElements<TFacets extends IAnalysisFacets>(props: AnalysisToolbarFormElementsProps<TFacets>) {
  const {
    dateRangeShortcuts,
    fieldInputValues,
    fields,
    fieldsConfig,
    fieldSelectedValues,
    fieldsSort,
    isSelectAllEnabled,
    isSortEnabled,
    onFieldChange,
    onSortChange,
    slotProps,
  } = props;

  const { t } = useTranslation();

  const elements = useValue(
    "elements",
    () => {
      const elements: Array<JSX.Element> = [];
      // Iterate fields
      fields?.forEach((field, index) => {
        const fieldConfig = fieldsConfig.find((configItem) => configItem.field === field);
        if (fieldConfig) {
          const fieldName = fieldConfig.field;
          const fieldLabel = t(fieldConfig.label);
          const selectedValue = fieldSelectedValues[field];
          const inputValue = fieldInputValues[field];

          // Render based on field type
          switch (fieldConfig.type) {
            case AnalysisFieldTypeEnum.Date:
              const dateRange = (inputValue ? inputValue : {}) as IDateRangeLimits;
              const selectedDate = selectedValue as IDateRange;
              const selectedDateShortcut = fieldSelectedValues[`${field as string}Shortcut` as keyof TFacets];
              const dataDaterangeSlotProps = slotProps?.dataDateRangePicker;
              // Add element
              elements.push(
                <Grid item sx={{ ml: index > 0 ? 3 : 0 }} key={fieldName}>
                  <FormControl sx={{ width: 255 }}>
                    <DataDateRangePicker
                      shortcutItems={dateRangeShortcuts}
                      dateRange={dateRange}
                      value={selectedDate}
                      shortcutValue={selectedDateShortcut as string}
                      isDisabled={false}
                      label={fieldLabel}
                      localeText={{ start: t("Components.Charts.From"), end: t("Components.Charts.To") }}
                      onDateRangeChange={(dateRange: IDateRange, shortcut?: string) => {
                        onFieldChange(fieldName, {
                          dateRange,
                          shortcut,
                        });
                      }}
                      {...dataDaterangeSlotProps}
                    />
                  </FormControl>
                </Grid>,
              );
              break;
            case AnalysisFieldTypeEnum.ArrayOfStrings:
            case AnalysisFieldTypeEnum.String:
              const options = (inputValue || []) as Array<ISelectInputFieldOption>;
              const selectedValueCopy = (selectedValue || []) as Array<string>;
              const selectSlotProps = slotProps?.select;
              const { hideLabel = false, sx: selectSX = {}, formControlSize } = selectSlotProps || {};
              const selectedCount = options.filter((option) => selectedValueCopy.includes(option.key)).length;
              // Add element
              elements.push(
                <Grid key={field as string} item sx={{ ml: index > 0 ? 3 : 0, width: 150 }}>
                  <FormControl sx={{ width: 140 }} size={formControlSize}>
                    {hideLabel ? null : <InputLabel htmlFor={field as string}>{fieldLabel}</InputLabel>}
                    <Select
                      sx={{ width: 150, ...selectSX }}
                      id={fieldName}
                      label={hideLabel ? "" : fieldLabel}
                      multiple
                      value={selectedValueCopy}
                      onChange={(event) => {
                        const newValues = event.target.value;
                        if (Array.isArray(newValues)) {
                          onFieldChange(fieldName, newValues, options);
                        }
                      }}
                      inputProps={{
                        name: field,
                        id: field as string,
                      }}
                      renderValue={(selected) =>
                        t("Components.Charts.SelectedOfTotal", {
                          selected: selectedCount,
                          total: options.length,
                        })
                      }
                      MenuProps={{
                        classes: { paper: "analysis-select-menu" },
                        MenuListProps: { classes: { root: "analysis-select-menu-list" } },
                      }}
                    >
                      {Array.isArray(inputValue)
                        ? renderMenuItems({
                            options: inputValue || [],
                            selected: selectedValueCopy,
                            sortOrder: fieldsSort ? fieldsSort[fieldName] : undefined,
                            isSelectAllEnabled: isSelectAllEnabled ?? fieldConfig.isSelectAllEnabled,
                            isSortingEnabled: isSortEnabled ?? fieldConfig.isSortEnabled,
                            onSort: (selectedSortOption: FacetDisplaySortType) => {
                              onSortChange && onSortChange(fieldName, selectedSortOption);
                            },
                          })
                        : null}
                    </Select>
                  </FormControl>
                </Grid>,
              );
              break;
            default:
              break;
          }
        }
      });
      return elements;
    },
    [
      fieldInputValues,
      fields,
      fieldsConfig,
      fieldSelectedValues,
      fieldsSort,
      isSelectAllEnabled,
      isSortEnabled,
      onFieldChange,
      onSortChange,
      dateRangeShortcuts,
      t,
    ],
  );

  return (
    <Box>
      <Grid container sx={{ flexWrap: "nowrap" }}>
        {elements}
      </Grid>
    </Box>
  );
}
