import { CommandOutputType } from "@bigpi/cookbook";
import { IHtmlDocumentShape } from "@bigpi/tl-schema";
import { createShapeId, TLShapePartial, type Editor as TldrawEditor } from "@tldraw/tldraw";
import { TFunction } from "i18next";

import { ICommandResponsePlugInInputs } from "../WorkspaceBoardCommandManager";
import { ResponsePlugInBase } from "./ResponsePlugInBase";

export class HtmlDocumentResponsePlugIn extends ResponsePlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = CommandOutputType.Html;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: ICommandResponsePlugInInputs): Promise<undefined | JSX.Element> {
    const { app, commandResponse, t } = input;

    if (!app) {
      console.warn(`No app instance for command response ${commandResponse.requestId}`);
      return;
    }

    // Check if we can update the status of an existing shape
    const shape = app.getShape(createShapeId(commandResponse.requestId));
    if (!shape) {
      console.warn(`No HtmlDocument shape ${commandResponse.requestId} found for command response.`);
    } else {
      this.updateShapeStatus(app, t, commandResponse.requestId, commandResponse.status);
    }
  }

  // *********************************************
  // Protected methods
  // *********************************************/
  protected updateShapeStatus(editor: TldrawEditor, t: TFunction<"translation", undefined>, requestId: string, status: string) {
    // Get the lock status
    const asyncUpdateLock = !(status === "success" || status === "failure");

    // NOTE: This plug-in doesn't currently support updating the HTML of an existing shape as it's done server-side.
    // However, we do set the error message if the command failed, so that it can be localized and displayed to the user.
    const shape: TLShapePartial<IHtmlDocumentShape> = {
      id: createShapeId(requestId),
      type: "htmlDocument",
      props: {
        asyncUpdateLock,
      },
    };

    if (status === "failure") {
      shape.props!.html = t("Components.WorkspaceBoardCommandResult.GenericError");
    }

    editor.updateShapes([shape]);
  }
}
