import { CommandOutputType, IPlugIn } from "@bigpi/cookbook";
import { ICommandResponsePlugInInputs } from "../WorkspaceBoardCommandManager";

export abstract class ResponsePlugInBase implements IPlugIn<ICommandResponsePlugInInputs, undefined | JSX.Element> {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  abstract name: CommandOutputType;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public abstract execute(input: ICommandResponsePlugInInputs): Promise<undefined | JSX.Element>;

  /**
   * @inheritdoc
   */
  public async destroy(): Promise<void> {
    // Do nothing in base class
  }
}
