import { ContentTypes } from "@bigpi/cookbook";
import ArticleIcon from "@mui/icons-material/Article";
import HtmlIcon from "@mui/icons-material/Html";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export interface IWorkspaceAttachmentIconProps {
  mimetype: string;
}

export default function WorkspaceAttachmentIcon(props: IWorkspaceAttachmentIconProps) {
  const { mimetype } = props;

  switch (mimetype) {
    case ContentTypes.PDF:
      return <PictureAsPdfIcon className="workspace-attachment-card-avatar" />;

    case ContentTypes.DOC:
    case ContentTypes.DOCX:
      return <InsertDriveFileIcon className="workspace-attachment-card-avatar" />;

    case ContentTypes.PLAIN_TEXT:
    case ContentTypes.RTF:
      return <ArticleIcon className="workspace-attachment-card-avatar" />;

    case ContentTypes.HTML:
      return <HtmlIcon className="workspace-attachment-card-avatar" />;

    default:
      return <InsertDriveFileIcon className="workspace-attachment-card-avatar" />;
  }
}
