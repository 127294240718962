import { COMMAND_GENERATE_DISTILLED_TRANSCRIPT_TEMPLATE, ICommandRequest, getObjectHash } from "@bigpi/cookbook";
import { SelectableText } from "@bigpi/cutlery";
import { useAuthUser } from "@frontegg/react";
import { Box, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, DataGridPremium } from "@mui/x-data-grid-premium";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getNameWithOccupationTitle } from "BoardComponents/Utils/TranscriptUtils";
import { CommandContext } from "CommandContext";
import { useCommandExecutor } from "Components/CommandManagers/useCommandExecutor";
import { DistilledTranscriptQuery, TranscriptItem, TranscriptRole, TranscriptSession } from "GraphQL/Generated/Apollo";
import TranscriptItemView from "../../BoardComponents/FeedShape/TranscriptItemView";

import "./QuestionsMasterDetails.css";

interface DistilledQuestionsToAnswerMapping extends TranscriptItem {
  answers: Array<TranscriptItem>;
}

export interface IQuestionsMasterDetailsProps {
  transcriptId: string;
  distilledTranscriptData?: DistilledTranscriptQuery["distilledTranscript"];
}

export default function QuestionsMasterDetails(props: IQuestionsMasterDetailsProps) {
  const { transcriptId, distilledTranscriptData } = props;

  const { t } = useTranslation();
  const user = useAuthUser();
  const commandExecutor = useCommandExecutor();

  // Kick off a request for data generation if it doesn't exist yet
  useEffect(() => {
    if (distilledTranscriptData === null) {
      const command = {
        ...COMMAND_GENERATE_DISTILLED_TRANSCRIPT_TEMPLATE,
      };

      // Create the overrides/values that are not set automatically by CommandExecutor
      const commandRequest: Partial<ICommandRequest> = {
        // Create deduplicationId
        deduplicationId: getObjectHash({
          commandId: command.id,
          organizationId: user?.tenantId,
          transcriptId,
        }),
      };

      // Set the selected transcript into the context to be picked up from there
      CommandContext.patchCommandContext({
        selection: {
          transcriptId,
        },
      });

      commandExecutor.executeCommand(command, commandRequest);
    }
  }, [distilledTranscriptData]);

  const getDataGridColdDef = useCallback(
    (): Array<GridColDef<DistilledQuestionsToAnswerMapping>> => [
      {
        field: "speech",
        flex: 1,
        headerName: t("Components.QuestionsMasterDetails.GridLabel.Questions"),
        maxWidth: 800,
        renderCell: (params: GridRenderCellParams) => {
          return params.value.join(" ");
        },
        sortable: false,
      },
    ],
    [t],
  );

  if (distilledTranscriptData === null) {
    return (
      <Typography variant="body1" gutterBottom>
        {t("Components.QuestionsMasterDetails.Generating")}
        <span className="ellipsis-anim">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </Typography>
    );
  }

  const distilledQnaSegments = distilledTranscriptData?.distilledSegments.filter(
    (distilledSegment) => distilledSegment.session === TranscriptSession.QuestionAnswer,
  );
  const distilledQuestionsToAnswerMapping: Array<DistilledQuestionsToAnswerMapping> = [];
  distilledQnaSegments?.forEach((distilledQnaSegment) => {
    let newMappings: Array<{ question: TranscriptItem; answers: Array<TranscriptItem> }> = [];
    for (let item of distilledQnaSegment.distilledTranscriptItems) {
      if (item.role === TranscriptRole.Analyst) {
        newMappings.push({
          question: item,
          answers: [],
        });
      } else if (newMappings.length > 0) {
        newMappings[newMappings.length - 1].answers.push(item);
      }
    }

    if (newMappings.length > 0) {
      distilledQuestionsToAnswerMapping.push(
        ...newMappings.map((mapping) => ({ ...mapping.question, answers: mapping.answers })),
      );
    }
  });

  function getDetailPanelContent({ row }: { row: DistilledQuestionsToAnswerMapping }) {
    return (
      <Box sx={{ flex: 1, mx: "auto", p: 2, pl: 6, backgroundColor: "var(--astra-color-gray10)" }}>
        <SelectableText variant="body1" gutterBottom fontWeight="500">
          {t("Components.QuestionsMasterDetails.AskedByLabel", {
            name: getNameWithOccupationTitle(t, row.fullName, row.occupationTitles),
          })}
        </SelectableText>
        {row.answers.map((answer) => (
          <TranscriptItemView key={answer.itemIndex} transcriptItem={answer} />
        ))}
      </Box>
    );
  }

  return (
    <>
      {distilledQuestionsToAnswerMapping && distilledQuestionsToAnswerMapping.length ? (
        <DataGridPremium
          classes={{
            cell: "question-master-details-datagrid__cell",
            root: "question-master-details-datagrid",
            row: "question-master-details-datagrid__row",
          }}
          getRowId={(row) => row.itemIndex}
          getRowHeight={() => "auto"}
          rows={distilledQuestionsToAnswerMapping}
          columns={getDataGridColdDef()}
          getDetailPanelHeight={() => "auto"}
          getDetailPanelContent={getDetailPanelContent}
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
        />
      ) : (
        <Typography variant="h6" gutterBottom>
          {t("Components.QuestionsMasterDetails.NoQuestions")}
        </Typography>
      )}
    </>
  );
}
