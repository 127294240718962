import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { useCreateWorkspaceBoardMutation } from "GraphQL/Generated/Apollo";

export interface INewWorkspaceBoardDialogProps {
  open: boolean;
  onClose: () => void;
  workspaceId: string;
}

export default function NewWorkspaceBoardDialog(props: INewWorkspaceBoardDialogProps) {
  const { open, workspaceId } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [boardName, setBoardName] = useState("");
  const [boardNameFieldFocused, setBoardNameFieldFocused] = useState(true);

  const boardNameFieldRef = useRef<HTMLInputElement>(null);

  const [createWorkspaceBoard] = useCreateWorkspaceBoardMutation();

  function onClose() {
    setErrorMessage("");
    setBoardName("");
    props.onClose();
  }

  async function onCreateNewWorkspaceBoard() {
    try {
      const result = await createWorkspaceBoard({
        variables: { input: { name: boardName, workspaceId } },
        refetchQueries: ["WorkspaceBoards", "Workspaces"],
      });

      // Open new workspace board
      const newBoardId = result.data?.createWorkspaceBoard?.id;
      if (newBoardId) {
        navigate(`/workspaces/${workspaceId}/boards/${newBoardId}`, { replace: true });
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(t("Components.NewWorkspaceBoardDialog.Error"));
    }
  }

  useEffect(() => {
    if (open && boardNameFieldRef.current) {
      boardNameFieldRef.current.focus();
    }
  }, [boardNameFieldRef.current, open]);

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="md">
      <DialogTitle variant="h5">{t("Components.NewWorkspaceBoardDialog.Title")}</DialogTitle>
      <DialogContent>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <TextField
          autoFocus={true}
          margin="dense"
          label={t("Components.NewWorkspaceBoardDialog.NameLabel")}
          type="text"
          fullWidth
          variant="standard"
          value={boardName}
          onBlur={() => setBoardNameFieldFocused(false)}
          onChange={(e) => setBoardName(e.target.value ?? "")}
          onFocus={() => setBoardNameFieldFocused(true)}
          required
          inputRef={boardNameFieldRef}
          error={boardName === "" && !boardNameFieldFocused}
        />
        <DialogContentText variant="caption">{t("Components.NewWorkspaceBoardDialog.Tip")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onCreateNewWorkspaceBoard} disabled={boardName === ""}>
            {t("Components.NewWorkspaceBoardDialog.Create")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
