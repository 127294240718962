import { CommandOutputType } from "@bigpi/cookbook";
import { IHeadlineShape } from "@bigpi/tl-schema";
import { createShapeId, TLShapePartial, type Editor as TldrawEditor } from "@tldraw/tldraw";
import { TFunction } from "i18next";

import { ICommandResponsePlugInInputs } from "../WorkspaceBoardCommandManager";
import { ResponsePlugInBase } from "./ResponsePlugInBase";

export class HeadlineSimulatorResponsePlugIn extends ResponsePlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = CommandOutputType.HeadlineSimulator;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: ICommandResponsePlugInInputs): Promise<undefined | JSX.Element> {
    const { app, commandResponse, t } = input;

    if (!app) {
      console.warn(`No app instance for command response ${commandResponse.requestId}`);
      return;
    }

    // Check if we can update the status of an existing shape
    const shape = app.getShape(createShapeId(commandResponse.requestId));
    if (!shape) {
      console.warn(`No HeadlineSimulator shape ${commandResponse.requestId} found for command response.`);
    } else {
      this.updateShapeStatus(app, t, commandResponse.requestId, commandResponse.status);
    }
  }

  // *********************************************
  // Protected methods
  // *********************************************/
  protected updateShapeStatus(editor: TldrawEditor, t: TFunction<"translation", undefined>, requestId: string, status: string) {
    // Only handle/modify the shape for failures
    if (status === "failure") {
      const shape: TLShapePartial<IHeadlineShape> = {
        id: createShapeId(requestId),
        type: "headline",
        isLocked: false,
        props: {
          status,
        },
      };

      editor.updateShapes([shape]);
    }
  }
}
