// *********************************************
// Public constants
// *********************************************/
export const ATTACHMENT_DOWNLOAD_PROXY_PATH = "attachment";
export const ATTACHMENT_DOWNLOAD_ROUTE_TEMPLATE = `/${ATTACHMENT_DOWNLOAD_PROXY_PATH}/:attachmentId`;

export const DOCUMENT_IMAGE_DOWNLOAD_PROXY_PATH = "doc-image";
export const DOCUMENT_IMAGE_DOWNLOAD_ROUTE_TEMPLATE = `/${DOCUMENT_IMAGE_DOWNLOAD_PROXY_PATH}/:organizationId/documents/:documentId/images/:imageFilename`;

export const EXPORT_TO_WORD_PROXY_PATH = "export-to-word";
export const EXPORT_TO_WORD_ROUTE_TEMPLATE = `/${EXPORT_TO_WORD_PROXY_PATH}`;

export const WORKSPACE_ASSET_DOWNLOAD_PROXY_PATH = "wsb-asset";
export const WORKSPACE_ASSET_DOWNLOAD_ROUTE_TEMPLATE = `/${WORKSPACE_ASSET_DOWNLOAD_PROXY_PATH}/workspaces/:workspaceId/workspaceBoards/:workspaceBoardId/assets/:filename`;

export const WORKSPACE_ATTACHMENT_DOWNLOAD_PROXY_PATH = "workspace-attachment";
export const WORKSPACE_ATTACHMENT_DOWNLOAD_ROUTE_TEMPLATE = `/${WORKSPACE_ATTACHMENT_DOWNLOAD_PROXY_PATH}/workspaces/:workspaceId/attachments/:workspaceAttachmentId/:filename`;
