import { Editor } from "@bigpi/editor-tiptap";
import EventEmitter from "emittery";
import { useMemo } from "react";

export type ChatActionExecutorEvents = {
  chatAction: OnChatActionEventArgs;
};

export type OnChatActionEventArgs = {
  action: string;
  data: any;
};
export type OnChatActionHandler = (event: OnChatActionEventArgs) => void;

/**
 * Delegates requested chat action execution to current in-scope chat action manager.
 */
export class ChatActionExecutor extends EventEmitter<ChatActionExecutorEvents> {
  // *********************************************
  // Public static methods
  // *********************************************/
  public static useNewChatActionExecutor = () => {
    const result = useMemo(() => new ChatActionExecutor(), []);

    return result;
  };

  // *********************************************
  // Public properties
  // *********************************************/
  public chatEditor?: Editor;

  // *********************************************
  // Public methods
  // *********************************************/
  public async executeChatAction(action: string, data: any) {
    // Create the event data based on the original run chatAction data
    const eventData: OnChatActionEventArgs = {
      action,
      data,
    };

    // Execute the chatAction request (call at least our event handler that's defined in this component)
    await this.raiseChatAction(eventData);
  }

  public async raiseChatAction(event: OnChatActionEventArgs) {
    await this.emitSerial("chatAction", event);
  }
}
