import { gql } from "@apollo/client";

export const UPSERT_WORKSPACES_PAGE_USER_PREFERENCE = gql`
  mutation UpsertWorkspacesPageUserPreferences($input: UpsertUserPreferenceInput!) {
    upsertUserPreference(input: $input) {
      key
      data
    }
  }
`;

export const UPSERT_WORKSPACE_PAGE_USER_PREFERENCE = gql`
  mutation UpsertWorkspacePageUserPreferences($input: UpsertUserPreferenceInput!) {
    upsertUserPreference(input: $input) {
      key
      data
    }
  }
`;

export const UPSERT_WORKSPACE_USER_PREFERENCE = gql`
  mutation UpsertWorkspaceUserPreferences($input: UpsertUserPreferenceInput!) {
    upsertUserPreference(input: $input) {
      key
      data
    }
  }
`;
