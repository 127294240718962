import { AnalysisFeedbackDataItemType } from "@bigpi/cookbook";
import { Permissions, hasPermission } from "@bigpi/permission";
import { useAuthUser } from "@frontegg/react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import HasPermission from "Components/HasPermission/HasPermission";
import {
  useCreateAnalysisFeedbackMutation,
  useUpdateAnalystQuestionMutation,
  useUpdateTopicDiscussionExampleMutation,
  useUpdateTopicDiscussionInNewsArticleMutation,
  useUpdateTopicDiscussionSummaryExampleMutation,
} from "GraphQL/Generated/Apollo";

export interface IAnalysisFeedbackDialogProps {
  dataItemType: AnalysisFeedbackDataItemType;
  dataItemIds: Array<string>;
  onClose: () => void;
  open: boolean;
}

export function AnalysisFeedbackDialog(props: IAnalysisFeedbackDialogProps) {
  const { dataItemType, dataItemIds, open } = props;
  const { t } = useTranslation();
  const user = useAuthUser();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [removeRecords, setRemoveRecords] = React.useState(false);
  const [feedbackContent, setFeedbackContent] = React.useState("");
  const [feedbackContentFocused, setFeedbackContentFocused] = React.useState(true);
  const feedbackContentRef = React.useRef<HTMLInputElement>(null);
  const [createAnalysisFeedbackMutation, { error: analysisFeedbackMutationError, loading: analysisFeedbackMutationLoading }] =
    useCreateAnalysisFeedbackMutation();
  const [updateAnalystQuestionMutation, { error: AnalystQuestionMutationError, loading: AnalystQuestionMutationLoading }] =
    useUpdateAnalystQuestionMutation();
  const [
    updateTopicDiscussionExampleMutation,
    { error: TopicDiscussionExampleMutationError, loading: TopicDiscussionExampleMutationLoading },
  ] = useUpdateTopicDiscussionExampleMutation();
  const [
    updateTopicDiscussionInNewsArticleMutation,
    { error: TopicDiscussionInNewsArticleMutationError, loading: TopicDiscussionInNewsArticleMutationLoading },
  ] = useUpdateTopicDiscussionInNewsArticleMutation();
  const [
    updateTopicDiscussionSummaryExampleMutation,
    { error: TopicDiscussionSummaryExampleMutationError, loading: TopicDiscussionSummaryExampleMutationLoading },
  ] = useUpdateTopicDiscussionSummaryExampleMutation();

  const onClose = React.useCallback(() => {
    setErrorMessage("");
    setFeedbackContent("");
    props.onClose();
  }, [props.onClose]);

  const onSend = React.useCallback(
    async function onSend() {
      try {
        // Create a separate feedback row for each data item id
        for (let index = 0; index < dataItemIds.length; index++) {
          const dataItemId = dataItemIds[index];

          await createAnalysisFeedbackMutation({
            variables: { input: { dataItemId, dataItemType, feedbackContent, isHidden: removeRecords } },
          });

          if (removeRecords) {
            // Double-check user permissions
            if (
              hasPermission(
                user.permissions.map((permission) => permission.key),
                Permissions.PlatformDataDelete,
              )
            ) {
              // Mark the requested items hidden
              switch (dataItemType) {
                case AnalysisFeedbackDataItemType.AnalystQuestion:
                  await updateAnalystQuestionMutation({
                    variables: { input: { id: dataItemId, isHidden: true } },
                    refetchQueries: index === dataItemIds.length - 1 ? ["AnalystQuestions"] : [],
                  });
                  break;
                case AnalysisFeedbackDataItemType.TopicDiscussionExample:
                  await updateTopicDiscussionExampleMutation({
                    variables: { input: { id: dataItemId, isHidden: true } },
                    refetchQueries: index === dataItemIds.length - 1 ? ["TopicDiscussionExamples"] : [],
                  });
                  break;
                case AnalysisFeedbackDataItemType.TopicDiscussionInNewsArticle:
                  await updateTopicDiscussionInNewsArticleMutation({
                    variables: { input: { id: dataItemId, isHidden: true } },
                    refetchQueries: index === dataItemIds.length - 1 ? ["TopicDiscussionInNewsArticles"] : [],
                  });
                  break;
                case AnalysisFeedbackDataItemType.TopicDiscussionSummaryExample:
                  await updateTopicDiscussionSummaryExampleMutation({
                    variables: { input: { id: dataItemId, isHidden: true } },
                    refetchQueries: index === dataItemIds.length - 1 ? ["TopicDiscussionSummaryExamples"] : [],
                  });
                  break;
                default:
                  throw new Error(`Unsupported data item type: ${dataItemType}`);
              }
            }
          }
        }

        onClose();
      } catch (error) {
        console.error(error);
        setErrorMessage(t("Components.AnalysisFeedbackDialog.Error"));
      }
    },
    [
      createAnalysisFeedbackMutation,
      dataItemIds,
      dataItemType,
      feedbackContent,
      removeRecords,
      onClose,
      t,
      updateAnalystQuestionMutation,
      updateTopicDiscussionExampleMutation,
      updateTopicDiscussionInNewsArticleMutation,
      updateTopicDiscussionSummaryExampleMutation,
      user.permissions,
    ],
  );

  React.useEffect(() => {
    if (open && feedbackContentRef.current) {
      feedbackContentRef.current.focus();
    }
  }, [feedbackContentRef.current, open]);

  const loading =
    analysisFeedbackMutationLoading ||
    AnalystQuestionMutationLoading ||
    TopicDiscussionExampleMutationLoading ||
    TopicDiscussionInNewsArticleMutationLoading ||
    TopicDiscussionSummaryExampleMutationLoading;
  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="md">
      <DialogTitle variant="h5">{t("Components.AnalysisFeedbackDialog.Title")}</DialogTitle>
      <DialogContent>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <TextField
          autoFocus={true}
          error={feedbackContent === "" && !feedbackContentFocused}
          fullWidth
          inputRef={feedbackContentRef}
          label={t("Components.AnalysisFeedbackDialog.FeedbackLabel", { count: dataItemIds.length })}
          margin="dense"
          multiline={true}
          onBlur={() => setFeedbackContentFocused(false)}
          onChange={(e) => setFeedbackContent(e.target.value ?? "")}
          onFocus={() => setFeedbackContentFocused(true)}
          required
          rows={4}
          type="text"
          value={feedbackContent}
          // variant="standard"
        />
        <HasPermission permission={Permissions.PlatformDataDelete}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event, checked) => {
                  setRemoveRecords(checked);
                }}
              />
            }
            label={t("Components.AnalysisFeedbackDialog.RemoveItems", { count: dataItemIds.length })}
          />
        </HasPermission>
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
        <Button onClick={async () => onSend()} variant="contained" disabled={feedbackContent === "" || loading}>
          {t("Components.AnalysisFeedbackDialog.Send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
