import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AdminPortal, useAuthUser, useAuthActions } from "@frontegg/react";
import { Box, Divider, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import Help from "@mui/icons-material/Help";
import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import { SwitchAccount } from "@mui/icons-material";
import { useApolloClient } from "@apollo/client";

import OrganizationSelectDialog from "Components/OrganizationSelectDialog/OrganizationSelectDialog";
import UserAvatar from "Components/UserAvatar/UserAvatar";

export default function SettingsMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAuthUser();
  const { switchTenant } = useAuthActions();
  const apolloClient = useApolloClient();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [organizationSelectDialogOpen, setOrganizationSelectDialogOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const { tenantIds } = user;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrganizationSelectDialogClose = (id: string) => {
    if (id !== user.tenantId) {
      switchTenant({
        tenantId: id,
        callback: (data, error) => {
          apolloClient.resetStore().then(() => {
            navigate("/");
          });
        },
      });
    }

    setOrganizationSelectDialogOpen(false);
  };

  const handleLogout = async () => {
    await apolloClient.clearStore();
    navigate("/account/logout");
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("Components.SettingsMenu.AccountSettings") || ""} placement="right">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <UserAvatar user={{ id: user.id, name: user.name, profilePictureUrl: user.profilePictureUrl }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 24,
              height: 24,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => AdminPortal.show()}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Components.SettingsMenu.Settings")} />
        </MenuItem>
        {window.FreshworksWidget && (
          <MenuItem
            onClick={() => {
              window.FreshworksWidget("open");
            }}
          >
            <ListItemIcon>
              <Help fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Components.SettingsMenu.Help")} />
          </MenuItem>
        )}
        <Divider />
        {tenantIds && tenantIds.length > 1 && (
          <MenuItem
            onClick={() => {
              setOrganizationSelectDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <SwitchAccount fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Components.SettingsMenu.SwitchOrganization")} />
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Components.SettingsMenu.SignOut")} />
        </MenuItem>
        <Box sx={{ display: "flex", m: "6px 16px" }}>
          <Typography variant="caption" display="block">
            Version {process.env.REACT_APP_VERSION} •{" "}
            <a target="_blank" href="/licenses.html">
              Licenses
            </a>
          </Typography>
        </Box>
      </Menu>
      <OrganizationSelectDialog
        open={organizationSelectDialogOpen}
        onClose={handleOrganizationSelectDialogClose}
        selectedValue={user.tenantId}
      />
    </React.Fragment>
  );
}
