import { useAuthUser } from "@frontegg/react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Stack, Typography, useTheme, List, ListItem, Tooltip } from "@mui/material";
import { useValue } from "@tldraw/tldraw";
import { useTranslation } from "react-i18next";

import { ChatMessageChannelType, useChatMessageQuery } from "GraphQL/Generated/Apollo";
import ChatMessage from "Chat/ChatMessage/ChatMessage";
import { getChatMessageToolbarActions } from "Chat/Utils/ChatMessageToolbarActions";

// *********************************************
// Type/Interface
// *********************************************
interface ThreadMessageProps {
  channelId: string;
  channelType: ChatMessageChannelType;
  onClose: () => void;
  parentId: string;
}

// *********************************************
// Component
// *********************************************
export function ThreadMessage(props: ThreadMessageProps) {
  const { channelId, channelType, parentId, onClose } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const user = useAuthUser();

  const { data } = useChatMessageQuery({
    variables: {
      id: parentId,
      channelType: channelType,
    },
  });

  const toolbarActions = useValue(
    "",
    () => {
      if (data?.chatMessage) {
        return getChatMessageToolbarActions(data?.chatMessage, user.id, "thread");
      } else {
        return [];
      }
    },
    [data?.chatMessage, user.id],
  );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
        spacing={2}
        sx={{
          pl: 2,
          position: "sticky",
          top: 0,
          zIndex: 2,
          backgroundColor: "#fff",
        }}
      >
        <Typography variant="subtitle1" component="span" sx={{ fontWeight: 500 }}>
          {t("Components.Chat.MessageList.Thread")}
        </Typography>
        <Tooltip title={t("Global.Action.Close")} placement="left">
          <Button onClick={onClose}>
            <CloseIcon color="action" />
          </Button>
        </Tooltip>
      </Stack>
      <List sx={{ padding: 0 }}>
        {data?.chatMessage && (
          <ChatMessage
            channelId={channelId}
            channelType={channelType}
            message={{ ...data?.chatMessage, childCount: 0 }}
            onMessageParentSet={() => {}}
            toolbarActions={toolbarActions}
          />
        )}
        {data?.chatMessage?.childCount && data?.chatMessage?.childCount > 0 ? (
          <ListItem>
            <Stack direction="row" justifyContent="flex-start" width="100%">
              <Typography component="span" variant="caption" mr={1}>
                {t("Components.Chat.Message.ViewThread", { count: data?.chatMessage?.childCount })}
              </Typography>
              <hr style={{ flex: 1, border: "none", borderBottom: `1px solid ${theme.palette.divider}` }} />
            </Stack>
          </ListItem>
        ) : null}
      </List>
    </>
  );
}
