import { useAuthUser } from "@frontegg/react";
import { CircularProgress, Fade, LinearProgress, Typography } from "@mui/material";
import {
  ObjectRole,
  useCreateAttachmentAccessControlListMutation,
  useDeleteAttachmentAccessControlListMutation,
  useAttachmentAccessControlListByObjectQuery,
  useAttachmentAccessControlListUserRolesQuery,
} from "GraphQL/Generated/Apollo";
import { useTranslation } from "react-i18next";

import AccessControlListDropdown from "Components/AccessControlList/AccessControlListDropdown";
import AccessControlListTable from "Components/AccessControlList/AccessControlListTable";
import HasAttachmentRole from "Components/HasAttachmentRole/HasAttachmentRole";

export interface AttachmentAccessControlListEditorProps {
  attachmentId: string;
}

export default function AttachmentAccessControlListEditor(props: AttachmentAccessControlListEditorProps) {
  const { attachmentId } = props;
  const { t } = useTranslation();
  const user = useAuthUser();
  const [createAttachmentAccessControlListMutation, { data: createData, loading: createLoading, error: createError }] =
    useCreateAttachmentAccessControlListMutation();
  const [deleteAttachmentAccessControlListMutation, { data: deleteData, loading: deleteLoading, error: deleteError }] =
    useDeleteAttachmentAccessControlListMutation();
  const { data, loading, error } = useAttachmentAccessControlListByObjectQuery({
    variables: {
      attachmentId,
    },
    fetchPolicy: "no-cache",
  });
  const {
    data: aclData,
    error: aclError,
    loading: aclLoading,
  } = useAttachmentAccessControlListUserRolesQuery({
    variables: {
      userId: user.id,
      attachmentId,
    },
  });

  // Check if we should be in read-only mode
  const readOnly =
    aclData?.attachmentAccessControlListUserRoles.roles.some((assignedRole) =>
      [ObjectRole.ContentManager, ObjectRole.Contributor, ObjectRole.Manager, ObjectRole.Owner].includes(assignedRole),
    ) !== true;

  function handleOnAddAcl(subjectId: string, subjectType: string, role: ObjectRole) {
    createAttachmentAccessControlListMutation({
      variables: {
        input: {
          attachmentId: attachmentId,
          userId: subjectType === "User" ? subjectId : undefined,
          userGroupId: subjectType === "UserGroup" ? subjectId : undefined,
          role,
        },
      },
      refetchQueries: ["AttachmentAccessControlListByObject", "Attachments", "Attachment"],
    });
  }

  function handleOnDeleteAcl(id: string) {
    deleteAttachmentAccessControlListMutation({
      variables: {
        input: {
          id,
        },
      },
      refetchQueries: ["AttachmentAccessControlListByObject", "Attachments", "Attachment"],
    });
  }

  const isLoading = loading || createLoading || deleteLoading || aclLoading;
  return (
    <>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {t("Components.AttachmentAccessControlListEditor.Description")}
        <Fade
          in={isLoading}
          style={{
            transitionDelay: isLoading ? "800ms" : "0ms",
          }}
        >
          <LinearProgress />
        </Fade>
      </Typography>
      <AccessControlListTable
        rows={data?.attachmentAccessControlListByObject || []}
        onDelete={handleOnDeleteAcl}
        showActions={!readOnly}
      />
      <HasAttachmentRole attachmentId={attachmentId} roles={[ObjectRole.Manager, ObjectRole.Owner]}>
        <AccessControlListDropdown allowEveryone={true} onAdd={handleOnAddAcl} />
      </HasAttachmentRole>
    </>
  );
}
